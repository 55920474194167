import { gql, useMutation } from "@apollo/client";
import { useToaster } from "swash/Toast";
import {
  EnumSelectList,
  EnumSelectState,
  useEnumSelectState,
} from "swash/v2/EnumSelect";

import { useSafeQuery } from "@/containers/Apollo";
import { ExposureIcon } from "@/containers/ExposureIcon";
import { ExposureIndicator } from "@/containers/ExposureIndicator";

import { Range } from "./util/publicationSlots";

const ExposureFragment = gql`
  fragment ArticleExposuresSelect_exposure on Exposure {
    id
    ...ExposureIndicator_exposure
  }
  ${ExposureIndicator.fragments.exposure}
`;

const ArticleExposureFragment = gql`
  fragment ArticleExposuresSelect_articleExposure on ArticleExposure {
    ...ExposureIndicator_articleExposure
    exposure {
      ...ExposureIndicator_exposure
    }
    article {
      ...ExposureIndicator_article
    }
    publication {
      ...ExposureIndicator_publication
    }
  }
  ${ExposureIndicator.fragments.articleExposure}
  ${ExposureIndicator.fragments.article}
  ${ExposureIndicator.fragments.publication}
  ${ExposureIndicator.fragments.exposure}
`;

const ArticleFragment = gql`
  fragment ArticleExposuresSelect_article on Article {
    articleExposures {
      nodes {
        ...ArticleExposuresSelect_articleExposure
      }
    }
  }
  ${ArticleExposureFragment}
`;

const ExposureQuery = gql`
  query ArticleExposuresSelect_exposure {
    exposures(where: { enabled: true }) {
      nodes {
        id
        ...ArticleExposuresSelect_exposure
      }
    }
  }
  ${ExposureFragment}
`;

const Mutation = gql`
  mutation ArticleExposuresSelect_updateArticle(
    $articleId: Int!
    $articleExposures: [ArticleExposureInput!]!
  ) {
    updateArticle(
      input: { id: $articleId, articleExposures: $articleExposures }
    ) {
      id
      ...ArticleExposuresSelect_article
    }
  }
  ${ArticleFragment}
`;

export type Exposure = {
  id: number;
  label: string;
  icon: string;
  acronym: string;
  type: string;
  requiredPriorArticlePublication: boolean;
  warnPosteriorArticlePublication: boolean;
};

export type ExposureQueryData = {
  exposures: {
    nodes: Exposure[];
  };
};

type ExposureVariables = {
  where: { enabled: boolean };
};

type Planning = {
  date: string;
  range: Range;
};

export type ArticleExposure = {
  gid: string;
  exposureId: number;
  exposure: Exposure;
  correlatedPlannedDateToArticle: boolean;
  article: {
    initialFirstPublished: boolean;
    printInfos: {
      exportedAt: string;
      exportedBy: {
        id: number;
        fullName: string;
      };
      decorrelated: boolean;
      decorrelatedAt: string;
    };
    schedulerDate: string;
    planning: Planning;
  };
  publication: {
    id: number;
    date: string;
  };
  planning: Planning;
  fulfilled: boolean;
  fulfilledAt: string;
  selected: boolean;
  selectedAt: string;
  suggested: boolean;
  suggestedAt: string;
  plannedDatetimeSlots: { startBound: string; endBound: string };
};

export type ArticleExposuresSelectStateProps = {
  article: {
    id: number;
    articleExposures: { nodes: ArticleExposure[] };
  };
};

const getArticleExposure = (
  articleExposures: ArticleExposure[],
  exposure: Exposure,
) => {
  return articleExposures.find(
    (articleExposure) => articleExposure.exposureId === exposure.id,
  );
};

export const useArticleExposuresSelectState = (
  props: ArticleExposuresSelectStateProps,
): EnumSelectState<Exposure> => {
  const articleExposureIds = props.article.articleExposures.nodes
    .filter((node) => node.suggested || node.fulfilled || node.selected)
    .map((exposure) => exposure.exposureId);

  const { data } = useSafeQuery<ExposureQueryData, ExposureVariables>(
    ExposureQuery,
    { variables: { where: { enabled: true } } },
  );
  const exposures = data?.exposures.nodes ?? [];

  const [updateArticleExposures] = useMutation(Mutation);
  const toaster = useToaster();
  const enumSelect = useEnumSelectState({
    value: exposures.filter(({ id }) => articleExposureIds.includes(id)),
    onChange: (value) => {
      updateArticleExposures({
        variables: {
          articleId: props.article.id,
          articleExposures: value.map((exposure) => ({
            exposureId: exposure.id,
          })),
        },
        optimisticResponse: {
          __typename: "Mutation",
          updateArticle: {
            ...props.article,
            articleExposures: {
              ...props.article.articleExposures,
              nodes: value.map((exposure) => {
                const articleExposure = getArticleExposure(
                  props.article.articleExposures.nodes,
                  exposure,
                );

                return {
                  ...articleExposure,
                  suggested: true,
                  fulfilled: articleExposure?.fulfilled ?? false,
                };
              }),
            },
          },
        },
      }).catch(() => {
        toaster.danger("La mise à jour du média a échoué");
      });
    },
    items: exposures,
    iconSelector: (exposure) => {
      // @ts-expect-error JS component
      return <ExposureIcon exposure={exposure} />;
    },
    labelSelector: (exposure) => exposure.label,
    valueSelector: (exposure) => exposure.id.toString(),
    disabledSelector: (exposure) => {
      const articleExposure = getArticleExposure(
        props.article.articleExposures.nodes,
        exposure,
      );
      if (!articleExposure) return false;
      return articleExposure.fulfilled || articleExposure.selected;
    },
    required: false,
  });
  return enumSelect;
};

useArticleExposuresSelectState.fragments = {
  article: ArticleFragment,
};

export type ArticleExposuresSelectListProps = {
  state: EnumSelectState<Exposure>;
};

export const ArticleExposuresSelectList = (
  props: ArticleExposuresSelectListProps,
) => {
  return <EnumSelectList state={props.state} />;
};
