import { Form as ReactFinalForm } from "react-final-form";

import { FormDirtyFieldsProvider, useDirtyFieldsRef } from "./FormDirtyFields";
import { useFocusOnErrorDecorator } from "./FormFocusOnError";
import { FormSubmitterProvider, useSubmitterRef } from "./FormSubmitter";

const BaseFinalForm = ({ name, onSubmit, decorators = [], ...props }) => {
  const focusOnError = useFocusOnErrorDecorator(name);
  const submitterRef = useSubmitterRef();
  const dirtyFieldsRef = useDirtyFieldsRef();
  return (
    <ReactFinalForm
      subscription={{}}
      decorators={[...decorators, focusOnError]}
      name={name}
      onSubmit={async (values, form) => {
        const context = {};
        // submitter is inspired from https://developer.mozilla.org/en-US/docs/Web/API/SubmitEvent/submitter
        // it represents the button that triggers the submit of the form
        Object.defineProperty(context, "submitter", {
          get: () => submitterRef.current,
        });
        Object.defineProperty(context, "dirtyFields", {
          get: () => dirtyFieldsRef.current,
        });
        return onSubmit(values, form, context);
      }}
      {...props}
    />
  );
};

export const BaseForm = (props) => {
  return (
    <FormSubmitterProvider>
      <FormDirtyFieldsProvider>
        <BaseFinalForm {...props} />
      </FormDirtyFieldsProvider>
    </FormSubmitterProvider>
  );
};
