import { createContext, useContext } from "react";
import { createSelector } from "swash/utils/selector";

const ArticleSelector = createSelector();
const ArticleIdContext = createContext();
const ArticleHasCustomFieldsContext = createContext();

export const ArticleContextProvider = ({
  article,
  hasCustomFields = false,
  children,
}) => {
  return (
    <ArticleIdContext.Provider value={article.id}>
      <ArticleHasCustomFieldsContext.Provider value={hasCustomFields}>
        <ArticleSelector.Provider state={article}>
          {children}
        </ArticleSelector.Provider>
      </ArticleHasCustomFieldsContext.Provider>
    </ArticleIdContext.Provider>
  );
};

export const useArticleId = () => {
  return useContext(ArticleIdContext);
};

export const useArticleHasCustomFields = () => {
  return useContext(ArticleHasCustomFieldsContext);
};

export const useArticleSelector = ArticleSelector.useSelector;
