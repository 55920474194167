import { Button } from "ariakit/button";
import clsx from "clsx";
import * as React from "react";
import { ButtonHTMLAttributes, forwardRef } from "react";

export type ClickableProps = ButtonHTMLAttributes<HTMLDivElement>;

export const Clickable = forwardRef<HTMLDivElement, ClickableProps>(
  ({ className, ...props }, ref) => {
    return (
      <Button
        ref={ref}
        as="div"
        className={clsx(
          className,
          "select-none rounded aria-expanded:bg-grey-bg-hover-transparent hover:bg-grey-bg-hover-transparent",
        )}
        {...props}
      />
    );
  },
);
