import { gql } from "@apollo/client";
import moment, { Moment } from "moment-timezone";
import { memo, useMemo } from "react";

import { EmbargoTime } from "@/components/EmbargoTime";
import { useRemoteConfig } from "@/containers/RemoteConfig";

export type SpreadArticlePublishTimeProps = {
  initialFirstPublished: string | null;
  planning: {
    date: string | null;
    range: string | null;
  };
  scheduled: boolean;
  isUrgent: boolean;
  isEmbargo: boolean;
};

export const HourRange = ({
  date,
  dateFormat = "HH:mm",
  literal = false,
}: {
  date: string;
  dateFormat?: string;
  literal?: boolean;
}) => {
  const { publicationSlots } = useRemoteConfig();

  const nextHour: Moment = useMemo(() => {
    const hour = moment(date).tz("Europe/Paris").hour();
    let nextHourLocal = 0;

    publicationSlots?.forEach((publicationSlot: number, index: number) => {
      if (hour === publicationSlot && index !== publicationSlots.length - 1) {
        nextHourLocal = publicationSlots[index + 1] ?? hour;
      } else if (
        hour === publicationSlot &&
        index === publicationSlots.length - 1
      ) {
        nextHourLocal = publicationSlots[0] ?? hour;
      }
    });
    return moment(date)
      .tz("Europe/Paris")
      .add(nextHourLocal > hour ? nextHourLocal - hour : 24 - hour, "hours");
  }, [publicationSlots, date]);

  if (!publicationSlots) return null;
  return (
    <>
      {moment(date).tz("Europe/Paris").format(dateFormat)}{" "}
      {!literal ? <span className="font-sans"> → </span> : <span> et</span>}{" "}
      {nextHour.format(dateFormat)}
    </>
  );
};

export const SpreadArticlePublishTime = memo<SpreadArticlePublishTimeProps>(
  (props) => {
    if (props.initialFirstPublished) {
      return (
        <div>
          {moment(props.initialFirstPublished)
            .tz("Europe/Paris")
            .format("HH:mm")}
        </div>
      );
    }
    if (props.scheduled && props.planning?.date && !props.isEmbargo) {
      return (
        <div>
          {moment(props.planning.date).tz("Europe/Paris").format("HH:mm")}
        </div>
      );
    }
    if (
      props.planning?.range &&
      ["week", "month"].includes(props.planning.range)
    ) {
      return null;
    }

    if (props.isUrgent) {
      return "ASAP" as any;
    }

    if (!props.planning?.date || props.planning?.range === "day") {
      return <div className="text-grey-on">HH:mm</div>;
    }
    if (props.planning?.range === "hour" && props.planning.date) {
      return <HourRange date={props.planning.date} />;
    }
    const formattedTime = moment(props.planning?.date)
      .tz("Europe/Paris")
      .format("HH:mm");
    if (props.isEmbargo) {
      return <EmbargoTime time={formattedTime} />;
    }
    return formattedTime;
  },
);

export type ArticlePublishTimeProps = {
  article: SpreadArticlePublishTimeProps;
};

export function ArticlePublishTime(props: ArticlePublishTimeProps) {
  return <SpreadArticlePublishTime {...props.article} />;
}

ArticlePublishTime.fragments = {
  article: gql`
    fragment ArticlePublishTime_article on Article {
      initialFirstPublished
      scheduled
      planning {
        date
        range
      }
      isUrgent
      isEmbargo
    }
  `,
};
