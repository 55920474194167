import { gql } from "@apollo/client";
import { createContext, useContext, useMemo, useState } from "react";

import { useSafeMutation } from "@/containers/Apollo";

const ArticleRevisionContext = createContext();
const PinnedRevisionContext = createContext();
const SetPinnedRevisionContext = createContext();

export const ArticleRevisionProvider = ({ children }) => {
  // Uses 0 for the current version
  const [articleRevisionId, setArticleRevisionId] = useState(0);
  const [pinnedRevision, setPinnedRevision] = useState(null);

  const value = useMemo(
    () => ({
      articleRevisionId,
      setArticleRevisionId,
    }),
    [articleRevisionId],
  );
  return (
    <ArticleRevisionContext.Provider value={value}>
      <PinnedRevisionContext.Provider value={pinnedRevision}>
        <SetPinnedRevisionContext.Provider value={setPinnedRevision}>
          {children}
        </SetPinnedRevisionContext.Provider>
      </PinnedRevisionContext.Provider>
    </ArticleRevisionContext.Provider>
  );
};

const useArticleRevisionContext = () => useContext(ArticleRevisionContext);

export const usePinnedRevision = () => useContext(PinnedRevisionContext);
export const useSetPinnedRevision = () => useContext(SetPinnedRevisionContext);

export const usePinnedRevisionId = () => {
  const pinnedRevision = usePinnedRevision();
  return pinnedRevision?.id;
};

export const useArticleRevisionId = () => {
  return useArticleRevisionContext().articleRevisionId;
};

export const useSetArticleRevisionId = () => {
  return useArticleRevisionContext().setArticleRevisionId;
};

export const useShowRevision = () => {
  const articleRevisionId = useArticleRevisionId();
  const pinnedRevisionId = usePinnedRevisionId();

  return articleRevisionId && pinnedRevisionId !== 0;
};

const RestoreMutation = gql`
  mutation RestoreArticleRevisionMutation(
    $input: RestoreArticleRevisionInput!
  ) {
    restoreArticleRevision(input: $input) {
      id
    }
  }
`;

const RestoreArticleRevisionContext = createContext();
const RestoringArticleRevisionContext = createContext();

export function RestoringArticleRevisionProvider({ children }) {
  const [restoreArticleRevision, { loading: restoring }] =
    useSafeMutation(RestoreMutation);

  return (
    <RestoreArticleRevisionContext.Provider value={restoreArticleRevision}>
      <RestoringArticleRevisionContext.Provider value={restoring}>
        {children}
      </RestoringArticleRevisionContext.Provider>
    </RestoreArticleRevisionContext.Provider>
  );
}

export function useRestoreArticleRevision() {
  return useContext(RestoreArticleRevisionContext);
}

export function useRestoringArticleRevision() {
  return useContext(RestoringArticleRevisionContext);
}
