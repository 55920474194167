import { gql } from "@apollo/client";
import { useEffect, useMemo, useState } from "react";
import { useField, useForm } from "react-final-form";
import { Button } from "swash/Button";
import { DialogDisclosure, useDialogState } from "swash/Dialog";
import {
  IoCogOutline,
  IoDesktopOutline,
  IoMoon,
  IoMoonOutline,
  IoPhonePortraitOutline,
} from "swash/Icon";
import { Tooltip } from "swash/Tooltip";

import { GlobalId } from "@/components/GlobalId";
import {
  CheckboxField,
  useCheckboxField,
} from "@/components/fields/CheckboxField";
import { FieldsetField } from "@/components/fields/Fieldset";
import { FormSavingIndicator } from "@/components/forms/FormSavingIndicator";
import { useSubscribeFormValue } from "@/components/forms/FormSubscribe";
import { useSafeQuery } from "@/containers/Apollo";
import { HasLevelAccess } from "@/containers/User";
import {
  EditorPanel,
  EditorPanelBody,
  StatusBar,
  StatusBarGroup,
  StatusBarItem,
} from "@/containers/common/EditorLayout";
import { HtmlEditor } from "@/containers/common/codemirror/Editor";

import { SnippetParamsDialog } from "./SnippetDialogs";

const PreviewContainer = (props) => (
  <div
    className="flex h-full flex-col border-4 border-l-0 border-grey-border-strong bg-dusk-bg-stronger focus:outline-none [&_button:focus]:text-inherit [&_button:hover]:text-inherit"
    {...props}
  />
);

const ConfigQuery = gql`
  query SnippetsConfigQuery {
    dbConfig {
      snippets {
        contextHtml
        darkModeAttributes
      }
    }
  }
`;

const SettingsDialogIconButton = ({ editor, snippet, operations }) => {
  const dialog = useDialogState();

  return (
    <>
      <DialogDisclosure state={dialog}>
        {(disclosureProps) => (
          <Button
            {...disclosureProps}
            appearance="text"
            variant="secondary"
            iconOnly
            scale="sm"
            aria-label="Paramètres"
          >
            <IoCogOutline />
          </Button>
        )}
      </DialogDisclosure>
      <SnippetParamsDialog
        editor={editor}
        snippet={snippet}
        operations={operations}
        state={dialog}
      />
    </>
  );
};

const useSrcDoc = ({ rawDarkModeAttributes, contextHtml, code, colorMode }) => {
  const darkModeAttributes = useMemo(() => {
    try {
      return JSON.parse(rawDarkModeAttributes || null) ?? {};
    } catch (e) {
      return {};
    }
  }, [rawDarkModeAttributes]);

  const wrappedCode = `
  <script>
  // Silent all errors before the script is running
  window.onerror = (e) => console.error("Snippet error: " + e) || true
  </script>
  ${contextHtml || ""}
  ${code}
  <script>
    // Silent all errors after the script is running
    window.onerror = (e) => console.error("Snippet error: " + e) || true
  </script>
  `;

  return useMemo(() => {
    if (colorMode === "light") return wrappedCode;
    const element = document.createElement("div");
    Object.entries(darkModeAttributes || {}).forEach(([key, value]) => {
      element.setAttribute(key, value);
    });
    const [, start, end] = element.outerHTML.match(/(.*)(<\/div>)$/);
    return `${start}${wrappedCode}${end}`;
  }, [colorMode, wrappedCode, darkModeAttributes]);
};

const SnippetCardIframe = ({
  rawDarkModeAttributes,
  contextHtml,
  code,
  colorMode,
  device,
}) => {
  const srcDoc = useSrcDoc({
    rawDarkModeAttributes,
    contextHtml,
    code,
    colorMode,
  });
  const width = (() => {
    switch (device) {
      case "mobile":
        return 375; // iPhone X
      case "desktop":
      default:
        return "100%";
    }
  })();
  return (
    <iframe
      title="SnippetCard"
      srcDoc={srcDoc}
      frameBorder={0}
      marginWidth={0}
      marginHeight={0}
      style={{
        backgroundColor: "white",
        margin: "0 auto",
        border: 0,
        frameBorder: "0",
        marginWidth: "0",
        marginHeight: "0",
        width,
        height: "100%",
      }}
      sandbox="allow-forms allow-modals allow-popups allow-same-origin allow-scripts allow-same-origin"
    />
  );
};

const SnippetCard = ({ editor, snippet, operations }) => {
  const code = useSubscribeFormValue("code");
  const [device, setDevice] = useState("mobile");
  const [colorMode, setColorMode] = useState("light");

  const { data } = useSafeQuery(ConfigQuery);
  if (!data) return null;

  return (
    <PreviewContainer>
      <div className="relative flex-auto text-center">
        <SnippetCardIframe
          code={code}
          rawDarkModeAttributes={data.dbConfig.snippets?.darkModeAttributes}
          contextHtml={data.dbConfig.snippets?.contextHtml}
          colorMode={colorMode}
          device={device}
        />
      </div>
      <div className="border-t border-grey-border-strong">
        <StatusBar>
          <StatusBarGroup>
            <StatusBarItem className="border-r border-grey-border-strong">
              <Button
                appearance="text"
                variant="secondary"
                iconOnly
                scale="sm"
                aria-pressed={device === "mobile"}
                onClick={() => setDevice("mobile")}
                aria-label="Mode mobile"
              >
                <IoPhonePortraitOutline />
              </Button>
            </StatusBarItem>
            <StatusBarItem className="border-r border-grey-border-strong">
              <Button
                appearance="text"
                variant="secondary"
                iconOnly
                scale="sm"
                aria-pressed={device === "desktop"}
                onClick={() => setDevice("desktop")}
                aria-label="Mode desktop"
              >
                <IoDesktopOutline />
              </Button>
            </StatusBarItem>
            <ColorMode colorMode={colorMode} setColorMode={setColorMode} />
            <DarkModeField
              colorMode={colorMode}
              disabled={snippet.forceDarkModeSupported}
            />
          </StatusBarGroup>
          <StatusBarGroup>
            <StatusBarItem>
              <SettingsDialogIconButton
                editor={editor}
                snippet={snippet}
                operations={operations}
              />
            </StatusBarItem>
          </StatusBarGroup>
        </StatusBar>
      </div>
    </PreviewContainer>
  );
};

const DarkModeTooltip = ({ children }) => {
  return (
    <Tooltip
      tooltip={
        <>
          <div>
            Le dark mode a été activé de manière automatique grâce au
            commentaire :
          </div>
          <code className="font-mono">{"<!-- dark-mode-support -->"}</code>
        </>
      }
    >
      <div>{children}</div>
    </Tooltip>
  );
};

const DarkModeCheckBox = (props) => (
  <CheckboxField {...props}>Compatible mode sombre</CheckboxField>
);

const DarkModeField = ({ disabled, colorMode }) => {
  const name = "darkMode";
  const form = useForm();
  const field = useCheckboxField(name, {
    format: (v) => v === "supported",
    parse: (v) => (v ? "supported" : "notSupported"),
    disabled,
  });

  useEffect(() => {
    if (disabled) {
      form.change(name, "supported");
    }
  }, [form, disabled]);

  if (colorMode !== "dark") return null;

  return (
    <StatusBarItem p={0} pl={2} alignItems="center">
      {disabled ? (
        <DarkModeTooltip>
          <DarkModeCheckBox {...field} />
        </DarkModeTooltip>
      ) : (
        <DarkModeCheckBox {...field} />
      )}
    </StatusBarItem>
  );
};

const ColorMode = ({ colorMode, setColorMode }) => {
  return (
    <StatusBarItem className="border-r border-grey-border-strong">
      <Tooltip tooltip="Mode sombre">
        <Button
          appearance="text"
          variant="secondary"
          iconOnly
          scale="sm"
          onClick={() => setColorMode(colorMode === "light" ? "dark" : "light")}
          aria-pressed={colorMode === "dark"}
        >
          {colorMode === "dark" ? <IoMoon /> : <IoMoonOutline />}
        </Button>
      </Tooltip>
    </StatusBarItem>
  );
};

const CodeEditorInput = () => {
  const codeField = useField("code", {
    format: (v) => v || "",
    parse: (v) => v || "",
  });

  return (
    <EditorPanel className="border-4">
      <EditorPanelBody>
        <HtmlEditor {...codeField.input} />
      </EditorPanelBody>
    </EditorPanel>
  );
};

const SnippetCodeEditorHeader = ({
  snippet: { globalId, createdAt, updatedAt },
}) => {
  return (
    <div className="flex items-center justify-between">
      <div className="flex p-2 pt-0">
        <HasLevelAccess level="developer">
          {globalId ? <GlobalId globalId={globalId} /> : null}
        </HasLevelAccess>
      </div>
      <div className="flex p-2 pt-0">
        <FormSavingIndicator date={updatedAt ?? createdAt} />
      </div>
    </div>
  );
};

const SnippetCodeEditorContent = ({ editor, snippet, operations }) => {
  return (
    <div className="flex grow flex-wrap">
      <FieldsetField col={3 / 5} p={0}>
        <CodeEditorInput />
      </FieldsetField>
      <FieldsetField col={2 / 5} p={0}>
        <SnippetCard
          editor={editor}
          snippet={snippet}
          operations={operations}
        />
      </FieldsetField>
    </div>
  );
};

export const SnippetCodeEditor = ({ snippet, editor, operations }) => {
  return (
    <div className="flex h-full flex-col">
      <SnippetCodeEditorHeader snippet={snippet} />
      <SnippetCodeEditorContent
        editor={editor}
        snippet={snippet}
        operations={operations}
      />
    </div>
  );
};
