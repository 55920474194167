import { createContext, useCallback, useContext } from "react";
import { useStorage } from "swash/utils/useStorage";

const MAX_AUTHORIZED_IMAGES = 50;

type Image = {
  id: number;
};

type StorageAuthorizedGraphicContent = Image["id"][];

type AuthorizedGraphicContent = (imageId: Image["id"]) => boolean;

const GraphicContentContext = createContext<AuthorizedGraphicContent>(
  () => false,
);

type SetAuthorizedGraphicContent = (imageId: Image["id"]) => void;

const SetGraphicContentContext = createContext<SetAuthorizedGraphicContent>(
  () => null,
);

export const AuthorizedGraphicContentProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [imageIds, setImageIds] = useStorage<StorageAuthorizedGraphicContent>(
    "authorized-graphic-content-images",
    [],
  );

  const authorizeImage = useCallback(
    (imageId: number) => {
      setImageIds(([...ids]) => {
        if (ids.length >= MAX_AUTHORIZED_IMAGES) {
          ids.pop();
          ids.unshift(imageId);
          return ids;
        }
        ids.unshift(imageId);
        return ids;
      });
    },
    [setImageIds],
  );

  const isImageAuthorized = useCallback(
    (imageId: number) => {
      return imageIds.includes(imageId);
    },
    [imageIds],
  );

  return (
    <GraphicContentContext.Provider value={isImageAuthorized}>
      <SetGraphicContentContext.Provider value={authorizeImage}>
        {children}
      </SetGraphicContentContext.Provider>
    </GraphicContentContext.Provider>
  );
};

export const useAuthorizedGraphicContent = () =>
  useContext(GraphicContentContext);
export const useSetAuthorizedGraphicContent = () =>
  useContext(SetGraphicContentContext);
