import { gql } from "@apollo/client";
import { ButtonProps } from "swash/Button";

import { PublishedLink } from "@/components/PublishedLink";

export type ArticlePublishedLinkProps = {
  article: {
    url: string | null;
  };
} & { className?: string; scale?: ButtonProps["scale"] };

export const ArticlePublishedLink = (props: ArticlePublishedLinkProps) => {
  return (
    <PublishedLink
      url={props.article.url}
      className={props.className}
      scale={props.scale}
    />
  );
};

ArticlePublishedLink.fragments = {
  article: gql`
    fragment ArticlePublishedLink_article on Article {
      url
    }
  `,
};
