import { createContext, memo, useContext, useEffect, useMemo } from "react";
import { IoPencil } from "swash/Icon";
import { useLiveRef } from "swash/utils/useLiveRef";
import { usePrevious } from "swash/utils/usePrevious";

import { EditorBlockCapsuleToolbarButton } from "@/components/teleporters/EditorBlockCapsule";
import { HasPermission } from "@/containers/User";
import {
  RemoteEditSnippetDialogDisclosure,
  useEditSnippetRemoteDialogContext,
} from "@/containers/snippet/SnippetEditDialog";

const SnippetNodeEditSnippetContext = createContext();

export const useSnippetNodeEditSnippetContext = () =>
  useContext(SnippetNodeEditSnippetContext);

export const SnippetNodeEditSnippetProvider = ({ children, renderEditor }) => {
  const value = useMemo(
    () => ({
      renderEditor,
    }),
    [renderEditor],
  );
  return (
    <SnippetNodeEditSnippetContext.Provider value={value}>
      {children}
    </SnippetNodeEditSnippetContext.Provider>
  );
};

export const SnippetNodeEditSnippet = memo(({ snippet, onEdit }) => {
  return (
    <HasPermission
      permission={["snippets:create", "snippets:full"]}
      method="some"
    >
      <SnippetNodeEditSnippetContent snippet={snippet} onEdit={onEdit} />
    </HasPermission>
  );
});

const SnippetNodeEditSnippetContent = memo(({ snippet, onEdit }) => {
  const dialog = useEditSnippetRemoteDialogContext();
  const editing = dialog.open;
  const previousEditing = usePrevious(editing);
  const onEditRef = useLiveRef(onEdit);
  const { renderEditor } = useSnippetNodeEditSnippetContext();

  useEffect(() => {
    const onEdit = onEditRef.current;
    if (!onEdit) return;
    if (previousEditing === undefined) return;
    if (previousEditing === editing) return;
    onEdit(editing);
  }, [onEditRef, editing, previousEditing]);

  return (
    <RemoteEditSnippetDialogDisclosure
      dialogProps={{
        snippetId: snippet.id,
        editor: renderEditor({ snippetId: snippet.id }),
      }}
    >
      {(disclosureProps) => {
        return (
          <EditorBlockCapsuleToolbarButton
            ref={disclosureProps.ref}
            {...disclosureProps}
            title="Éditer le snippet"
            icon={IoPencil}
          />
        );
      }}
    </RemoteEditSnippetDialogDisclosure>
  );
});
