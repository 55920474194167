import { createContext, useContext, useRef } from "react";

const FormSubmitterContext =
  /** @type {import('react').Context<import('react').MutableRefObject<HTMLElement | null>>} */ (
    createContext()
  );

export const FormSubmitterProvider = ({ children }) => {
  const submitterRef = useRef();
  return (
    <FormSubmitterContext.Provider value={submitterRef}>
      {children}
    </FormSubmitterContext.Provider>
  );
};

export const useSubmitterRef = () => {
  return useContext(FormSubmitterContext);
};
