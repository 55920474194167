import { gql } from "@apollo/client";
import { memo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "swash/Link";

import { AlertStatus } from "@/containers/article/panels/exposure/alert-section/AlertStatus";

export const ArticleAlertLightHistoryDetail = memo(
  ({ alert, status, articleId }) => {
    return (
      <div className="flex flex-col gap-1">
        <Link asChild>
          <RouterLink
            to={`/articles/${articleId}/exposure/alert/${alert.id}`}
            target="_blank"
          >
            {alert.title}
          </RouterLink>
        </Link>
        <div className="text-grey-on">
          <AlertStatus
            alert={alert}
            status={status}
            createdAt={alert.createdAt}
            publishedAt={alert.publishedAt}
          />{" "}
          | {alert.creator.firstNameInitials} {alert.creator.lastName}
        </div>
      </div>
    );
  },
);

ArticleAlertLightHistoryDetail.fragments = {
  alert: gql`
    fragment ArticleAlertHistoryDetail_alert on Alert {
      id
      title
      createdAt
      publishedAt
      creator {
        id
        firstNameInitials
        lastName
      }
    }
  `,
};
