import { Dialog as AriakitDialog } from "ariakit/dialog";
import clsx from "clsx";
import { backdropProps } from "swash/Dialog";

export function PreviewDialog({
  label,
  className,
  dialogCloseButtonRef,
  children,
  state,
}) {
  return (
    <AriakitDialog
      backdropProps={backdropProps}
      state={state}
      className={clsx(
        className,
        `fixed inset-0 z-dialog flex flex-col items-center justify-center overflow-hidden
          p-3 focus:outline-0`,
      )}
      aria-label={label}
      onClick={(event) => {
        if (event.target === event.currentTarget) {
          state.hide();
        }
      }}
      initialFocusRef={dialogCloseButtonRef}
    >
      {state.open ? children : null}
    </AriakitDialog>
  );
}
