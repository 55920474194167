import { useApolloClient } from "@apollo/client";
import deepEqual from "deep-equal";
import { getIn } from "final-form";
import { useMemo } from "react";
import { useFormState } from "react-final-form";
import { useComboboxStore } from "swash/v2/Combobox";
import { RemoteSelect, useRemoteSelectState } from "swash/v2/RemoteSelect";

import { FieldError } from "@/components/fields/FieldError";
import { FieldGroup } from "@/components/fields/FieldGroup";
import { FieldHint } from "@/components/fields/FieldHint";
import { FieldLabel } from "@/components/fields/FieldLabel";
import { useSelectField } from "@/components/fields/SelectField";
import { mergeConnections, useSafeQuery } from "@/containers/Apollo";

export function HasManyField({
  name,
  query,
  searchVariables,
  required,
  searchable = false,
  label,
  "aria-label": ariaLabel,
  scale = "lg",
  hint,
  placeholder,
  labelSelector = (item) => item?.label,
  valueSelector = (item) => item?.id?.toString(),
  disabledSelector = () => false,
  iconSelector = () => null,
  labelElementSelector,
  fragment,
  modelName,
  renderExtraBlock,
  isEqual = deepEqual,
  disabled,
  ...others
}) {
  const combobox = useComboboxStore();
  const search = combobox.useState("value");

  const client = useApolloClient();

  const { initialValues } = useFormState({
    subscription: {
      initialValues: true,
    },
  });

  const initialQuery = useSafeQuery(query, {
    variables: {
      value: getIn(initialValues, name),
    },
  });

  const queryResult = useSafeQuery(query, {
    variables: {
      ...(search ? { search } : {}),
      ...searchVariables,
    },
  });

  const data = useMemo(() => {
    const tempData = queryResult.data ?? queryResult.previousData;
    if (!tempData)
      return {
        items: [],
        totalCount: 0,
        hasMore: false,
      };
    return {
      items: tempData.connection.nodes ?? [],
      totalCount: tempData.connection.totalCount ?? 0,
      hasMore: tempData.connection.pageInfo?.hasMore ?? false,
    };
  }, [queryResult.data, queryResult.previousData]);

  const format = (v) =>
    (v || []).map((v) =>
      client.readFragment({
        id: `${modelName}:${v}`,
        fragment,
        fragmentName: fragment.definitions[0].name.value,
      }),
    );
  const parse = (v) => (v || []).map((v) => v.id);
  const field = useSelectField(name, {
    required,
    format,
    parse,
    isEqual,
    label: ariaLabel || label,
    ...others,
  });
  const { value, onChange } = field.state.field.input;

  const state = useRemoteSelectState({
    title: label,
    appearance: "chip",
    value: value || [],
    onChange,
    data,
    combobox,
    searchable,
    fetchMore: (previousData) => {
      queryResult.fetchMore({
        variables: { offset: previousData.items.length },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!previousResult.connection) return previousResult;
          return {
            ...previousResult,
            connection: mergeConnections(
              previousResult.connection,
              fetchMoreResult.connection,
            ),
          };
        },
      });
    },
    getItem: (id) => {
      const value = client.readFragment({
        id: `${modelName}:${id}`,
        fragment,
        fragmentName: fragment.definitions[0].name.value,
      });
      if (!value) {
        throw new Error(`${modelName} (id: ${id}) not found`);
      }
      return value;
    },
    labelSelector,
    iconSelector,
    labelElementSelector,
    disabledSelector: (item) => disabled || disabledSelector(item),
    valueSelector,
    loading: queryResult.loading,
  });

  if (initialQuery.loading) return null;

  return (
    <FieldGroup {...field}>
      {label ? <FieldLabel {...field}>{label}</FieldLabel> : null}
      <FieldError {...field} />
      {hint ? <FieldHint {...field}>{hint}</FieldHint> : null}
      <RemoteSelect
        state={state}
        scale={scale}
        disabled={disabled}
        aria-label={ariaLabel || label}
        placeholder={placeholder || "Sélectionner..."}
        clearLabel="Vider"
      />
      {renderExtraBlock && data.items
        ? renderExtraBlock(data.items, value, onChange)
        : null}
    </FieldGroup>
  );
}
