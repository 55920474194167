import camelCase from "camelcase";
import * as icons from "swash/Icon";

export const NamedIcon = ({ name, ...props }) => {
  if (!name) {
    return null;
  }
  let iconName = camelCase(name, { pascalCase: true });

  const Icon =
    // eslint-disable-next-line import/namespace
    icons[iconName] ?? icons.IoHelpCircle;

  return <Icon {...props} />;
};
