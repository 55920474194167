import { Unit } from "@/components/charts/ForecastInsightsChart";

const formatPercent = (value: number) =>
  `${(value * 100)
    .toFixed(2)
    .toLocaleString()
    .replace(/[.,]0+$/, "")}%`;

export const valueFormatter = ({
  value,
  unit = "number",
}: {
  value?: number;
  unit?: Unit;
}) => {
  if (!value) return "–";
  switch (unit) {
    case "percent":
      return formatPercent(value);
    default:
      return value;
  }
};
