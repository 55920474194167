import moment from "moment-timezone";
import { useMemo } from "react";

import { RemoteConfig, useRemoteConfig } from "@/containers/RemoteConfig";

export type Range = "day" | "week" | "month" | "hour" | null;
export type FormattedPublicationSlots = Array<RemoteConfig["publicationSlots"]>;

// Function that transform [0, 1, 2, 3] to [[0, 1], [1, 2], [2, 3], [3, 0]]
export const publicationSlotBuilder = (props: {
  publicationSlots: RemoteConfig["publicationSlots"];
}) =>
  props.publicationSlots.map((value, index) => {
    const nextValue =
      index === props.publicationSlots.length - 1
        ? 0
        : props.publicationSlots?.[index + 1] ?? 0;
    return [value, nextValue];
  });

// Important slots needs to be in a reverse order : [22, 0] -> [0, 1] -> [1, 2] -> [2, 3]
export const getPublicationSlotInterval = (props: {
  publicationSlots: FormattedPublicationSlots;
  date: string;
}) => {
  const hours = moment(props.date).tz("Europe/Paris").hours();

  return props.publicationSlots.find((publicationSlot) => {
    const [start, end] = publicationSlot as [number, number];
    return (
      hours >= (start as number) &&
      (end ? hours <= end : hours >= (end as number))
    );
  });
};

const getTimeDifferenceBetweenSlot = (
  slot: RemoteConfig["publicationSlots"],
) => {
  const [start, end] = slot;
  if (slot.length < 2 || (!start && start !== 0) || (!end && end !== 0))
    return 0;

  if (start > end) return 24 - start + end;
  return end - start;
};

export const useSlotItems = (props: { plannedDate: string }) => {
  const { publicationSlots } = useRemoteConfig();
  const publicationSlotsChoices = useMemo(() => {
    const formattedPublicationSlots = publicationSlotBuilder({
      publicationSlots,
    });
    return formattedPublicationSlots.map((slot) => ({
      from: `${slot[0]?.toLocaleString("fr-FR", {
        minimumIntegerDigits: 2,
      })}:00:00`,
      to: `${slot[1]?.toLocaleString("fr-FR", {
        minimumIntegerDigits: 2,
      })}:00:00`,
    }));
  }, [publicationSlots]);

  const items = useMemo(() => {
    return publicationSlotsChoices.filter((slot, index) => {
      // always display last range
      if (index === publicationSlotsChoices.length - 1) return true;
      return (
        !moment(props.plannedDate).isSame(moment(), "day") ||
        (moment(props.plannedDate).isSame(moment(), "day") &&
          moment().isBefore(moment(slot.to, "HH:mm:ss")))
      );
    });
  }, [props, publicationSlotsChoices]);

  return items;
};

export const useResolvePublicationSlots = (props: {
  plannedDate?: string;
  range?: Range;
}) => {
  const { publicationSlots } = useRemoteConfig();

  if (!props.plannedDate)
    return {
      startDate: null,
      endDate: null,
      publicationSlotInterval: null,
    };

  if (!publicationSlots || !props.range || props.range !== "hour")
    return {
      startDate: props.plannedDate,
      endDate: props.plannedDate,
      publicationSlotInterval: null,
    };

  const publicationSlotInterval = getPublicationSlotInterval({
    publicationSlots: publicationSlotBuilder({ publicationSlots }).reverse(),
    date: props.plannedDate,
  });

  const endDate = publicationSlotInterval
    ? moment(props.plannedDate)
        .add(getTimeDifferenceBetweenSlot(publicationSlotInterval), "hours")
        .format("YYYY-MM-DDTHH:mm:ssZ")
    : props.plannedDate;

  return {
    startDate: props.plannedDate,
    endDate,
    publicationSlotInterval,
  };
};
