import clsx from "clsx";
import { memo } from "react";
import { IoAlert, IoCheckmark, IoEllipsisHorizontal } from "swash/Icon";

export type EventStatusIconProps = {
  status: "queued" | "in_progress" | "failure" | "timed_out" | "success";
  className?: string;
};

export const EventStatusIcon = memo<EventStatusIconProps>(
  ({ status, className }) => {
    switch (status) {
      case "queued":
      case "in_progress":
        return (
          <IoEllipsisHorizontal
            className={clsx(className, "inline-block text-warning-bg-strong")}
            role="status"
            aria-busy="true"
            aria-label="En cours"
          />
        );
      case "failure":
        return (
          <IoAlert
            className={clsx(className, "inline-block text-danger-bg-strong")}
            role="status"
            aria-label="Échoué"
          />
        );
      case "timed_out":
        return (
          <IoAlert
            className={clsx(className, "inline-block text-danger-bg-strong")}
            role="status"
            aria-label="Expiré"
          />
        );
      case "success":
        return (
          <IoCheckmark
            className={clsx(className, "inline-block text-success-bg-strong")}
            role="status"
            aria-label="Réussi"
          />
        );
      default:
        return null;
    }
  },
);
