import { useMemo } from "react";
import { Button } from "swash/Button";
import { IoSparkles } from "swash/Icon";
import { useToaster } from "swash/Toast";

import { Form } from "@/components/forms/Form";
import { FormErrorToaster } from "@/components/forms/FormError";
import { FormSubmit } from "@/components/forms/FormSubmit";
import {
  HasExperimentalFeature,
  HasPermission,
  useHasPermission,
} from "@/containers/User";
import { BooleanField, DateField, StringField } from "@/containers/admin/CRUD";

import { fieldsMap } from "../ImageFieldsMap";
import { RestrictionSection } from "../ImageRestrictions";
import { TypeField } from "../ImageTypeField";
import { FooterTeleporter } from "./ImageMultipleForm";
import { renderPlaceholder } from "./utils";

export const ImageMultipleFormContent = ({
  imageIds,
  data,
  updateImages,
  onCloseImportDialog,
  state,
}) => {
  const toaster = useToaster();
  const images = useMemo(() => data?.images.nodes ?? [], [data]);

  const handleSubmit = async (values, form) => {
    if (!data) return;

    const diff = Object.fromEntries(
      Object.entries(form.getState().modified)
        .map(([key, modified]) => (modified ? [key, values[key]] : false))
        .filter(Boolean),
    );

    try {
      await updateImages({
        variables: { input: { ids: imageIds, ...diff } },
      });
      state.hide();
      onCloseImportDialog();
    } catch (error) {
      toaster.danger("L’édition des images a échoué");
    }
  };

  const initialValues = useMemo(() => {
    if (!images.length) return {};

    return Object.values(fieldsMap).reduce((values, field) => {
      const { name, format = (v) => v ?? "" } = field;

      const getValue = () => {
        const value = images[0][name];
        const same = images.every((image) => image[name] === value);

        return format(same ? value : null);
      };

      return {
        ...values,
        [name]: getValue(),
      };
    }, {});
  }, [images]);

  return (
    <Form collaborative initialValues={initialValues} onSubmit={handleSubmit}>
      <FormErrorToaster />
      <Content images={images} />
      <FooterTeleporter.Source>
        <div className="flex gap-4">
          <Button
            type="button"
            variant="secondary"
            appearance="text"
            onClick={() => state.hide()}
          >
            Annuler
          </Button>
          <FormSubmit>
            Confirmer l’édition de {imageIds.length} images
          </FormSubmit>
        </div>
      </FooterTeleporter.Source>
    </Form>
  );
};

const GroupTypeField = ({ images }) => {
  const name = fieldsMap.type.name;
  const same = images.every((image) => image[name] === images[0][name]);
  const disabled = !same;

  return (
    <div className="flex flex-col gap-2">
      <TypeField
        name={name}
        label={fieldsMap.type.label}
        placeholder={renderPlaceholder(images, name)}
        disabled={disabled}
        required={!disabled}
      />
      {disabled ? (
        <div className="text-sm text-grey-on">
          <IoSparkles className="inline" /> Sirius a détecté automatiquement
          plusieurs types d’images différents parmi les images
        </div>
      ) : null}
    </div>
  );
};

const Content = ({ images }) => {
  const hasEditUseRightsPermission = useHasPermission([
    "article:image:editUseRights",
  ]);

  return (
    <div className="flex flex-col gap-4">
      <GroupTypeField images={images} />
      <DateField
        name={fieldsMap.shootingDate.name}
        label={fieldsMap.shootingDate.label}
        placeholder={renderPlaceholder(images, fieldsMap.shootingDate.name)}
        disabled={!hasEditUseRightsPermission}
      />
      <StringField
        name={fieldsMap.city.name}
        label={fieldsMap.city.label}
        placeholder={renderPlaceholder(images, fieldsMap.city.name)}
      />
      <StringField
        name={fieldsMap.credit.name}
        label={fieldsMap.credit.label}
        rich
        placeholder={renderPlaceholder(images, fieldsMap.credit.name)}
      />
      <StringField
        name={fieldsMap.photographer.name}
        label={fieldsMap.photographer.label}
        placeholder={renderPlaceholder(images, fieldsMap.photographer.name)}
      />
      <StringField
        name={fieldsMap.keywords.name}
        label={fieldsMap.keywords.label}
        placeholder={renderPlaceholder(images, fieldsMap.keywords.name)}
      />
      <HasExperimentalFeature feature="graphic-content">
        <HasPermission permission="images:graphicContent">
          <BooleanField
            appearance="switch"
            name={fieldsMap.graphicContent.name}
            label={fieldsMap.graphicContent.label}
            hint="Si cette option est cochée, l’image sera floutée pour les utilisateurs de Sirius qui ont choisi de ne pas voir les images sensibles"
          />
        </HasPermission>
      </HasExperimentalFeature>
      <div className="flex flex-col gap-2">
        <RestrictionSection images={images} multiple />
      </div>
    </div>
  );
};
