import { useApolloClient } from "@apollo/client";
import clsx from "clsx";
import deepEqual from "deep-equal";
import { omit } from "lodash-es";
import {
  cloneElement,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button } from "swash/Button";
import {
  EmptyState,
  EmptyStateAction,
  EmptyStatePicture,
  EmptyStateText,
  EmptyStateTitle,
} from "swash/EmptyState";
import { IoRefresh } from "swash/Icon";
import { PageLoader } from "swash/Loader";
import {
  Toolbar,
  ToolbarActions,
  ToolbarControls,
  ToolbarDrawer,
  ToolbarDrawerToggle,
  useToolbarDrawerState,
} from "swash/Toolbar";
import { useLiveRef } from "swash/utils/useLiveRef";
import { useResizeObserver } from "swash/utils/useResizeObserver";

import faceWithHeadBandage from "@/assets/imgs/emojis/face_with_head_bandage.png";
import faceWithMonocle from "@/assets/imgs/emojis/face_with_monocle.png";
import { SimpleInfiniteScroll } from "@/components/InfiniteScroll";
import { ListTotalCount } from "@/components/ListTotalCount";
import { useSearchParams } from "@/components/SearchParams";
import { Form } from "@/components/forms/Form";
import { FormAutoSubmit } from "@/components/forms/FormAutoSubmit";
import { FormReset } from "@/components/forms/FormReset";
import { mergeConnections, useFetchMoreQuery } from "@/containers/Apollo";
import { ScrollingProvider, useScrolling } from "@/containers/Scrolling";
import { checkUserHasPermissions, useUser } from "@/containers/User";
import { LayoutModeSwitcher } from "@/containers/search/LayoutModeContext";
import { useStorage } from "@/services/hooks/useStorage";
import * as storage from "@/services/localStorage";

import { useAmplitudeFormDecorator } from "../Amplitude";
import { SearchBoxField, SearchBoxOptions } from "./SearchBox";

const defaultConfig = {
  ignoreCount: ["search"],
  storage: { version: "2", ignore: ["search"] },
  filters: [],
  sorter: null,
  advancedSearch: true,
  layoutMode: null,
  onLayoutModeChange: null,
};

/**
 * @param {string} name
 * @param {StorageConfig | null} storage
 */
const useStoredValues = (name, storage) => {
  const key = useMemo(
    () =>
      storage
        ? ["search", name, storage.version].filter(Boolean).join(":")
        : null,
    [storage, name],
  );
  const [values, setValues] = useStorage(key);
  const storedValues = useMemo(
    () => (storage ? omit(values, storage.ignore ?? []) : null),
    [values, storage],
  );
  const setStoredValues = useCallback(
    (values) => {
      if (storage) {
        setValues(omit(values, storage.ignore ?? []));
      }
    },
    [storage, setValues],
  );
  return [storedValues, setStoredValues];
};

const getFilterCount = (state, { scope = null } = {}) => {
  const { filters, initialValues, values } = state;
  return filters.reduce((count, filter) => {
    const { scope: filterScope = "default" } = filter;
    if (scope && filterScope !== scope) return count;
    if (!deepEqual(initialValues[filter.name], values[filter.name])) {
      return count + 1;
    }
    return count;
  }, 0);
};

const checkHasActiveFilter = (state) => {
  if (state.values.search !== null) return true;
  return getFilterCount(state) > 0;
};

export function useSearchFormState(config) {
  const {
    name,
    advancedSearch = defaultConfig.advancedSearch,
    filters: configFilters = defaultConfig.filters,
    sorter = defaultConfig.sorter,
    storage = defaultConfig.storage,
    layoutMode = defaultConfig.layoutMode,
    onLayoutModeChange = defaultConfig.onLayoutModeChange,
    emptyStateElement = null,
  } = config;
  const user = useUser();
  const client = useApolloClient();
  const [storedValues, setStoredValues] = useStoredValues(name, storage);

  const [filters, setFilters] = useState([]);
  const refs = useRef({ filters: configFilters, storedValues });

  useEffect(() => {
    const fetchData = async () => {
      const { filters, storedValues } = refs.current;
      const result = (
        await Promise.all(
          filters.map(async (filter) => {
            let allowed = true;
            if (filter.permissions) {
              allowed = checkUserHasPermissions(user, filter.permissions, {
                method: "some",
              });
            }
            if (filter.condition) {
              const { condition, query } = filter;
              if (!storedValues?.[filter.name]) {
                try {
                  const { data } = await client.query({
                    query,
                    variables: {
                      skip: true,
                    },
                  });
                  allowed = condition(data);
                } catch (error) {
                  // eslint-disable-next-line no-console
                  console.error(error);
                  allowed = false;
                }
              }
              // filter should exist
            }

            return allowed ? filter : false;
          }),
        )
      ).filter(Boolean);
      refs.current.filters = result;
      setFilters(result);
    };

    fetchData();
  }, [client, configFilters, user]);

  const parseIgnore = useMemo(
    () =>
      refs.current.filters
        .filter((filter) => filter.parseSearchParam === false)
        .map((filter) => filter.name),
    [],
  );
  const initialValues = useMemo(() => {
    const { filters } = refs.current;
    const input = sorter ? [...filters, sorter] : filters;
    return input.reduce(
      (acc, filter) => {
        acc[filter.name] = filter.initialValue;
        return acc;
      },
      { search: null },
    );
  }, [sorter]);

  const [searchParams, setSearchParams] = useSearchParams(initialValues, {
    initialState: {
      ...initialValues,
      ...storedValues,
      search: null,
    },
    parseIgnore,
  });
  const values = searchParams;
  const valuesForLocalStorage = useMemo(
    () =>
      omit(
        values,
        refs.current.filters
          .filter((f) => f.persisted === false)
          .map((f) => f.name),
      ),
    [values],
  );
  const valuesToVariables = useCallback(
    (values) => {
      const { filters } = refs.current;
      return filters.reduce(
        (variables, filter) => {
          const parse = filter.spread
            ? (value) => value
            : (value) => ({ [filter.name]: value });
          return {
            ...variables,
            where: {
              ...variables.where,
              ...parse(values[filter.name]),
            },
          };
        },
        {
          orderBy: sorter ? sorter.parse(values[sorter.name]) : null,
          where: {
            search: values.search !== null ? String(values.search) : null,
          },
        },
      );
    },
    [sorter],
  );
  const variables = useMemo(
    () => valuesToVariables(values),
    [valuesToVariables, values],
  );
  const setValues = useCallback(
    (values) => {
      setSearchParams(values);
    },
    [setSearchParams],
  );

  useEffect(() => {
    setStoredValues(valuesForLocalStorage);
  }, [setStoredValues, valuesForLocalStorage]);

  const reset = useCallback(() => {
    setValues(initialValues);
  }, [initialValues, setValues]);

  const state = useMemo(
    () => ({
      name,
      initialValues,
      values,
      setValues,
      filters,
      sorter,
      layoutMode,
      onLayoutModeChange,
      variables,
      reset,
      advancedSearch,
      emptyStateElement,
    }),
    [
      name,
      initialValues,
      values,
      setValues,
      filters,
      sorter,
      layoutMode,
      onLayoutModeChange,
      variables,
      reset,
      advancedSearch,
      emptyStateElement,
    ],
  );

  return state;
}

const useSearchFormDrawerState = (name) => {
  const key = `search:${name}-drawer-visible`;
  const drawer = useToolbarDrawerState({
    defaultOpen: storage.getItem(key) ?? false,
  });
  const { open: drawerOpen } = drawer.expandDisclosure.disclosure;
  useEffect(() => {
    storage.setItem(key, drawerOpen);
  }, [key, drawerOpen]);
  return drawer;
};

const SearchFormDrawer = ({ drawerState, searchFormState, scale }) => {
  const activeCount = getFilterCount(searchFormState, { scope: "default" });
  return (
    <ToolbarDrawerToggle
      {...drawerState}
      count={activeCount}
      label={scale === "base" ? "Filtres" : null}
    />
  );
};

const useMeasureWidth = () => {
  const [width, setWidth] = useState(0);
  const containerRef = useResizeObserver(
    (entry) => {
      setWidth(entry.contentRect.width);
    },
    (element) => {
      if (element) {
        setWidth(element.getBoundingClientRect().width);
      }
    },
  );
  return [containerRef, width];
};

export const SearchForm = ({ state }) => {
  const drawer = useSearchFormDrawerState(state.name);
  const amplitudeFormDecorator = useAmplitudeFormDecorator({
    eventType: `search:${state.name}`,
  });
  const decorators = useMemo(
    () => [amplitudeFormDecorator],
    [amplitudeFormDecorator],
  );
  const mutators = useMemo(
    () => ({
      setSearch: (args, state, utils) => {
        utils.changeValue(state, "search", () => args[0]);
      },
    }),
    [],
  );
  const [toolbarRef, toolbarWidth] = useMeasureWidth();
  const scale = toolbarWidth < 800 ? "sm" : "base";
  const { setValues } = state;
  const handleSubmit = useCallback((values) => setValues(values), [setValues]);
  const drawerFilters = useMemo(
    () => state.filters.filter((filter) => !filter.scope),
    [state.filters],
  );
  const searchBoxFilters = useMemo(
    () => state.filters.filter((filter) => filter.scope === "search-box"),
    [state.filters],
  );

  return (
    <Form
      as="div"
      initialValues={state.values}
      onSubmit={handleSubmit}
      decorators={decorators}
      mutators={mutators}
    >
      <FormAutoSubmit />
      <Toolbar ref={toolbarRef} aria-label="Filtres">
        <ToolbarControls>
          {drawerFilters.length > 0 && (
            <SearchFormDrawer
              searchFormState={state}
              drawerState={drawer}
              scale={scale}
            />
          )}
          <div className="flex-1">
            <SearchBoxField advancedSearch={state.advancedSearch} scale={scale}>
              {searchBoxFilters.length > 0 && (
                <SearchBoxOptions>
                  {searchBoxFilters.map((filter) => (
                    <div
                      key={filter.name}
                      className="border-l border-l-dusk-border-light pl-2"
                    >
                      {filter.element}
                    </div>
                  ))}
                </SearchBoxOptions>
              )}
            </SearchBoxField>
          </div>
          {state.sorter ? (
            <div className="flex shrink-0 items-center gap-2">
              <div className="font-accent text-sm font-semibold">
                Trier par :
              </div>
              {cloneElement(state.sorter.element, { scale })}
            </div>
          ) : null}
          <LayoutModeSwitcher.Target />
        </ToolbarControls>
      </Toolbar>
      {drawerFilters.length > 0 && (
        <ToolbarDrawer {...drawer} aria-label="Filtres avancés">
          <ToolbarControls>
            {drawerFilters.map((filter) =>
              cloneElement(filter.element, {
                key: filter.name,
                query: filter.query,
              }),
            )}
            <ToolbarActions>
              <FormReset
                scale="sm"
                disabled={false}
                initialValues={state.initialValues}
              />
            </ToolbarActions>
          </ToolbarControls>
        </ToolbarDrawer>
      )}
    </Form>
  );
};

const SearchNoResultAbstract = memo(({ children, state, resettable }) => {
  return (
    <EmptyState className="flex-1">
      {children}
      {resettable && (
        <EmptyStateAction>
          <Button type="button" onClick={state.reset}>
            <IoRefresh />
            Réinitialiser ma recherche
          </Button>
        </EmptyStateAction>
      )}
    </EmptyState>
  );
});

export const SearchNoResult = memo(({ state }) => {
  const hasActiveFilter = checkHasActiveFilter(state);
  if (!hasActiveFilter && state.emptyStateElement)
    return state.emptyStateElement;
  return (
    <SearchNoResultAbstract state={state} resettable={hasActiveFilter}>
      <EmptyStatePicture src={faceWithMonocle} />
      <EmptyStateTitle>
        {hasActiveFilter
          ? "Aucun résultat ne correspond à votre recherche"
          : "Aucun résultat"}
      </EmptyStateTitle>
    </SearchNoResultAbstract>
  );
});

export const SearchError = memo(({ error, state }) => {
  const { reset } = state;
  useEffect(() => {
    if (
      error?.networkError?.statusCode === 400 &&
      error.networkError.result?.errors?.every(
        (error) => error.extensions.code === "BAD_USER_INPUT",
      )
    ) {
      reset();
    }
  }, [error, reset]);

  if (!checkHasActiveFilter(state)) {
    throw error;
  }

  return (
    <SearchNoResultAbstract state={state} resettable>
      <EmptyStatePicture src={faceWithHeadBandage} />
      <EmptyStateTitle>
        Une erreur est survenue lors de la recherche
      </EmptyStateTitle>
      <EmptyStateText>
        Vous pouvez tenter de réinitialiser vos filtres pour corriger le
        problème. S’il persiste, contactez le support.
      </EmptyStateText>
    </SearchNoResultAbstract>
  );
});

export const SearchQuery = forwardRef(
  ({ state, query, children, onFetch }, ref) => {
    const {
      error,
      data,
      loading,
      fetchMore,
      fetchMoreLoading,
      refetch,
      refetchLoading,
    } = useFetchMoreQuery(query, {
      variables: {
        ...state.variables,
        isGrid: state.layoutMode === "grid",
        isList: state.layoutMode === "list",
      },
      fetchPolicy: "network-only",
    });

    const refs = useLiveRef({ data, onFetch });
    refs.current.data = data;

    useImperativeHandle(ref, () => ({ refetch }), [refetch]);

    useEffect(() => {
      const { onFetch } = refs.current;
      if (onFetch) {
        onFetch({ data, loading, fetchMoreLoading, refetchLoading });
      }
    }, [refs, data, loading, fetchMoreLoading, refetchLoading]);

    const loadMore = useCallback(() => {
      const { data } = refs.current;
      fetchMore({
        variables: { offset: data.connection.nodes.length },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          return {
            connection: mergeConnections(
              previousResult.connection,
              fetchMoreResult.connection,
            ),
          };
        },
      });
    }, [fetchMore, refs]);

    if (error) {
      return <SearchError error={error} state={state} />;
    }

    if (loading) {
      return <PageLoader />;
    }

    const { nodes } = data.connection;

    if (nodes.length === 0) {
      return <SearchNoResult state={state} />;
    }

    return children({
      data,
      loadMore,
      loading: fetchMoreLoading,
      refetch,
    });
  },
);

const InnerSearchFormContainer = (props) => {
  const { scrollListenerRef, scrolling } = useScrolling();
  return (
    <div
      ref={scrollListenerRef}
      className={clsx(
        "relative flex-1 overflow-auto",
        scrolling && "group/search-form-container-scrolling",
      )}
      {...props}
    />
  );
};

export const SearchFormContainer = (props) => (
  <ScrollingProvider>
    <InnerSearchFormContainer {...props} />
  </ScrollingProvider>
);

export const SearchToolbar = (props) => (
  <div
    className="sticky top-0 z-40 -mb-4 flex items-center justify-end gap-4 px-8 pt-4 group-hover/search-form-container-scrolling:drop-shadow-lg"
    {...props}
  />
);

export const SearchFormList = ({ data, loading, loadMore, children }) => {
  return (
    <>
      <div className="px-4">
        <ListTotalCount totalCount={data.connection.totalCount} />
      </div>
      <div className="px-4 pb-4">{children(data)}</div>
      <SimpleInfiniteScroll
        hasMore={data.connection.pageInfo.hasMore}
        loadMore={loadMore}
        loading={loading}
      />
    </>
  );
};
