import { gql } from "@apollo/client";
import axios from "axios";
import queryString from "query-string";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button } from "swash/Button";
import { DialogDisclosure, useDialogState } from "swash/Dialog";
import { Link } from "swash/Link";

import { Fieldset, FieldsetField } from "@/components/fields/Fieldset";
import { useRemoteConfig } from "@/containers/RemoteConfig";
import { StringField } from "@/containers/admin/CRUD/fields";
import { createServiceForm } from "@/containers/admin/Integration";

import { UninstallationDialog } from "./UninstallationDialog";
import facebookLogo from "./logo.png";

export { default as logo } from "./logo.png";

function translateError(error) {
  switch (error) {
    case "access_denied":
      return "Accès refusé";
    case "no_page_access_granted":
      return "Vous devez autoriser Sirius à accéder à votre page";
    case "user_data_not_found":
      return "Nous n’avons pas réussi à récupérer les données de votre page";
    case "invalid_token":
      return "Votre token d’accès n’est plus valide, merci de vous reconnecter à Facebook";
    case "default":
    default:
      return "Une erreur s’est produite, merci de réessayer";
  }
}

export const IntegrationConfigFragment = gql`
  fragment FacebookIntegrationConfigFragment on FacebookIntegrationConfig {
    userAccessToken
    pageId
    linkQueryParams
  }
`;

const UninstallButton = ({ onConfirm }) => {
  const dialog = useDialogState();
  return (
    <>
      <DialogDisclosure state={dialog}>
        {(disclosureProps) => (
          <Button type="button" {...disclosureProps}>
            Désinstaller
          </Button>
        )}
      </DialogDisclosure>
      <UninstallationDialog onConfirm={onConfirm} state={dialog} />
    </>
  );
};

function Fields({ initialValues }) {
  const config = useRemoteConfig();
  const [{ isLoading, error, pageInfo }, setDataState] = useState({
    isLoading: false,
    error: null,
    pageInformation: null,
  });

  const location = useLocation();
  const locationSearchRef = useRef(location.search);

  useEffect(() => {
    const searchParams = new URLSearchParams(locationSearchRef.current);
    if (searchParams.get("error")) {
      setDataState((prevState) => ({
        ...prevState,
        error: translateError(searchParams.get("error")),
      }));
    }
  }, [locationSearchRef]);

  const integrationIsConfigured = initialValues.userAccessToken;

  // Getting user info when page mounts
  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const userResponse = await axios.get("/api/facebook/user");
        return setDataState((prevState) => ({
          ...prevState,
          pageInfo: userResponse.data,
        }));
      } catch (error) {
        return setDataState((prevState) => ({
          ...prevState,
          isLoading: false,
          error: translateError(error.response.data.error),
        }));
      }
    };

    if (integrationIsConfigured) {
      getUserInfo();
    }
  }, [integrationIsConfigured]);

  const handleLinkAccount = async () => {
    setDataState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    const params = queryString.stringify({
      client_id: config.facebook.appId,
      redirect_uri: config.facebook.redirectUri,
      scope: "pages_manage_posts,pages_read_engagement",
    });
    window.location.href = `https://www.facebook.com/dialog/oauth?${params}`;
  };

  const handleUnlinkAccount = async () => {
    setDataState((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    try {
      await axios.delete("/api/facebook/user");
      return window.location.reload();
    } catch (error) {
      return setDataState((prevState) => ({
        ...prevState,
        isLoading: false,
        error: translateError(error.response.data.error),
      }));
    }
  };

  return (
    <Fieldset>
      <FieldsetField>
        <div className="mb-2 flex items-center justify-between rounded-md border-2 border-grey-border-light p-4">
          <img src={facebookLogo} alt="Facebook" className="h-10 w-10" />
          <div className="ml-2 grow">
            <div className="font-semibold">Facebook</div>
            {integrationIsConfigured ? (
              pageInfo && (
                <div>
                  {pageInfo.userName} (
                  <Link href={pageInfo.link}>{pageInfo.name}</Link>)
                </div>
              )
            ) : (
              <div>
                Vous n’avez pas encore lié votre compte vous permettant de
                publier des articles sur une page Facebook.
              </div>
            )}
          </div>
          {integrationIsConfigured ? (
            <UninstallButton onConfirm={handleUnlinkAccount} />
          ) : (
            <Button onClick={handleLinkAccount} disabled={isLoading}>
              Lier un compte
            </Button>
          )}
        </div>
        {error && <div className="mb-2 text-danger-on-light">{error}</div>}
        <StringField
          name="linkQueryParams"
          label="Link parameters"
          hint={
            <>
              You can use{" "}
              <Link href="https://lodash.com/docs#template" target="_blank">
                template lodash
              </Link>{" "}
              with following variables: <code>article, alert</code>
            </>
          }
          required
        />
      </FieldsetField>
    </Fieldset>
  );
}

export const ServiceForm = createServiceForm({ Fields });
