import { createContext, useContext, useRef } from "react";

const FormDirtyFieldsContext =
  /** @type {import('react').Context<import('react').MutableRefObject<Record<string, boolean> | null>>} */ (
    createContext(null)
  );

export const FormDirtyFieldsProvider = ({ children }) => {
  const dirtyFieldsRef = useRef();
  return (
    <FormDirtyFieldsContext.Provider value={dirtyFieldsRef}>
      {children}
    </FormDirtyFieldsContext.Provider>
  );
};

export const useDirtyFieldsRef = () => {
  return useContext(FormDirtyFieldsContext);
};
