import { gql } from "@apollo/client";
import { memo, useCallback, useMemo } from "react";
import { createTeleporter } from "react-teleporter";
import { PageLoader } from "swash/Loader";
import { PanelSection } from "swash/Panel";
import { useToaster } from "swash/Toast";

import { Form } from "@/components/forms/Form";
import { FormAutoSubmit } from "@/components/forms/FormAutoSubmit";
import { FormSubmittingPrompt } from "@/components/forms/FormPrompt";
import { FormSavingIndicator } from "@/components/forms/FormSavingIndicator";
import { useSafeMutation, useSafeQuery } from "@/containers/Apollo";
import { useRemoteConfig } from "@/containers/RemoteConfig";
import {
  BooleanField,
  EnumField,
  StringField,
  useNodesToEnumArray,
} from "@/containers/admin/CRUD";

import { ActivitySection } from "../image/ImageActivityDialog";
import { ArticleField } from "./fields/Articles";
import { AspectRatioField } from "./fields/AspectRatioField";

const ArticleMediaFragment = gql`
  fragment ArticleImageEdit_articleMedia on ArticleMedia {
    id
    createdAt
    updatedAt
    metadata
    recommendedForLmm
    recommendedForPrint
    # Cache update of edit page
    defaultCropRegion {
      top
      left
      width
      height
    }
    media {
      id
      ... on Image {
        caption
      }
    }
  }
`;

const UpdateArticleMediaMutation = gql`
  mutation ArticleImageEdit_updateArticleMedia(
    $input: UpdateArticleMediaInput!
  ) {
    updateArticleMedia(input: $input) {
      id
      ...ArticleImageEdit_articleMedia
    }
  }
  ${ArticleMediaFragment}
`;

const ArticleMediasQuery = gql`
  query ArticleImageEdit_medias($articleId: Int!, $imageId: Int!) {
    image(id: $imageId) {
      id
      caption
      articleMedias(where: { articleId: { eq: $articleId } }) {
        id
        ...ArticleImageEdit_articleMedia
      }
    }

    article(id: $articleId) {
      id
      layoutFormat
    }

    aspectRatios {
      nodes {
        id
        ...AspectRatioField_aspectRatios
      }
    }

    dbConfig {
      articles {
        defaultAspectRatioId
      }
    }
  }
  ${ArticleMediaFragment}
  ${AspectRatioField.fragments.aspectRatios}
`;

export const ArticleImageEdit = memo(({ articleId, imageId }) => {
  const { data } = useSafeQuery(ArticleMediasQuery, {
    variables: { articleId, imageId },
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
  });

  if (!data) return <PageLoader />;

  const [articleMedia] = data.image.articleMedias;

  if (!articleMedia) {
    throw new Error(`Invariant: missing article media`);
  }

  return (
    <ArticleImageEditForm
      articleMedia={articleMedia}
      article={data.article}
      aspectRatios={data.aspectRatios.nodes}
      defaultAspectRatioId={data.dbConfig.articles.defaultAspectRatioId}
      imageCaption={data.image.caption}
    />
  );
});

const RecommendedForLmmField = memo(({ name, label }) => {
  const { features } = useRemoteConfig();
  if (!features.includes("image-field-recommended-for-lmm")) return null;
  return <BooleanField name={name} label={label} />;
});

const SizeField = ({ name, label }) => {
  const { images: { sizes = [] } = {} } = useRemoteConfig();

  const enumValues = useNodesToEnumArray(sizes, {
    valueSelector: "value",
    labelSelector: "label",
  });

  return (
    <EnumField
      name={name}
      label={label}
      clearable
      enum={enumValues}
      sortEntries={() => 1}
    />
  );
};

const enumValues = [
  { value: "left", label: "Gauche" },
  { value: "center", label: "Centre" },
  { value: "right", label: "Droite" },
];

const PositionField = ({ name, label }) => {
  return (
    <EnumField
      name={name}
      label={label}
      clearable
      enum={enumValues}
      sortEntries={() => 1}
    />
  );
};

export const ArticleImageIndicatorTeleporter = createTeleporter();

const ArticleImageEditForm = ({
  articleMedia,
  article,
  aspectRatios,
  defaultAspectRatioId,
  imageCaption,
}) => {
  const [updateArticleMedia] = useSafeMutation(UpdateArticleMediaMutation, {
    variables: {
      id: articleMedia.id,
    },
  });

  const toaster = useToaster();

  const isLargeArticle = article?.layoutFormat === "large";
  const aspectRatioId =
    Number(articleMedia.metadata?.aspectRatioId ?? defaultAspectRatioId) ??
    aspectRatios[0]?.id;

  const initialValues = useMemo(() => {
    return {
      aspectRatioId,
      caption: articleMedia.metadata.caption ?? imageCaption,
      portfolioTitle: articleMedia.metadata.portfolioTitle,
      portfolioLink: articleMedia.metadata.portfolioLink,
      recommendedForLmm: articleMedia.recommendedForLmm,
      recommendedForPrint: articleMedia.recommendedForPrint,
      ...(isLargeArticle && {
        size: articleMedia.metadata.size,
        position: articleMedia.metadata.position,
      }),
    };
  }, [aspectRatioId, articleMedia, isLargeArticle, imageCaption]);

  const handleSubmit = useCallback(
    async (values) => {
      try {
        await updateArticleMedia({
          variables: {
            input: {
              id: articleMedia.id,
              recommendedForLmm: values.recommendedForLmm,
              recommendedForPrint: values.recommendedForPrint,
              metadata: {
                aspectRatioId: values.aspectRatioId,
                caption: values.caption,
                portfolioTitle: values.portfolioTitle,
                ...((articleMedia.metadata.portfolioLink?.articleId ||
                  values.portfolioLink?.articleId) && {
                  portfolioLink: {
                    articleId: values.portfolioLink?.articleId,
                  },
                }),
                ...(isLargeArticle && {
                  size: values.size,
                  position: values.position,
                }),
              },
            },
          },
        });
      } catch {
        toaster.danger("La mise à jour de l’image a échoué");
      }
    },
    [articleMedia, isLargeArticle, toaster, updateArticleMedia],
  );

  return (
    <Form collaborative initialValues={initialValues} onSubmit={handleSubmit}>
      <FormAutoSubmit />
      <FormSubmittingPrompt />
      <ArticleImageIndicatorTeleporter.Source>
        <FormSavingIndicator
          date={articleMedia.updatedAt ?? articleMedia.createdAt}
        />
      </ArticleImageIndicatorTeleporter.Source>
      <PanelSection className="flex flex-col gap-4">
        <StringField
          name={fieldsMap.metadata.fields.caption.name}
          label={fieldsMap.metadata.fields.caption.label}
          placeholder="Saisir la légende qui s’affichera dans l’article"
          multiline
          rich
          scale="base"
        />
        <AspectRatioField
          name={fieldsMap.metadata.fields.aspectRatioId.name}
          label={fieldsMap.metadata.fields.aspectRatioId.label}
          aspectRatios={aspectRatios}
          w={1}
        />
        {isLargeArticle && (
          <>
            <SizeField
              name={fieldsMap.metadata.fields.size.name}
              label={fieldsMap.metadata.fields.size.label}
            />
            <PositionField
              name={fieldsMap.metadata.fields.position.name}
              label={fieldsMap.metadata.fields.position.label}
            />
          </>
        )}
      </PanelSection>
      <PanelSection className="flex flex-col gap-4">
        <BooleanField
          name={fieldsMap.recommendedForPrint.name}
          label={fieldsMap.recommendedForPrint.label}
        />
        <RecommendedForLmmField
          name={fieldsMap.recommendedForLmm.name}
          label={fieldsMap.recommendedForLmm.label}
        />
      </PanelSection>
      <PanelSection className="flex flex-col gap-4">
        <StringField
          name={fieldsMap.metadata.fields.portfolioTitle.name}
          label={fieldsMap.metadata.fields.portfolioTitle.label}
          rich
          placeholder="Saisir le titre qui s’affichera dans le portfolio"
          scale="base"
        />
        <ArticleField
          name={fieldsMap.metadata.fields.portfolioLink.name}
          label={fieldsMap.metadata.fields.portfolioLink.label}
          placeholder="Saisir le titre ou l’ID de l’article"
        />
      </PanelSection>
      <ActivitySection
        resource="articleMedia"
        resourceId={articleMedia?.id}
        fieldsMap={fieldsMap}
        title="Activités pour cet article"
        noComment
      />
    </Form>
  );
};

const fieldsMap = {
  metadata: {
    fields: {
      caption: {
        name: "caption",
        label: "Légende dans l’article",
      },
      aspectRatioId: {
        name: "aspectRatioId",
        label: "Format dans l’article",
        formatValue: (aspectRatioId, labels) =>
          labels[`$aspectRatio-${aspectRatioId}`],
      },
      size: { name: "size", label: "Taille dans l’article" },
      position: { name: "position", label: "Position dans l’article" },
      portfolioTitle: {
        name: "portfolioTitle",
        label: "Titre dans le portfolio",
      },
      portfolioLink: {
        name: "portfolioLink",
        label: "Lien dans le portfolio",
        fields: {
          articleId: { label: "Article" },
        },
      },
    },
  },
  recommendedForPrint: {
    name: "recommendedForPrint",
    label: "Recommandée pour le journal",
  },
  recommendedForLmm: {
    name: "recommendedForLmm",
    label: "Média d’appel La Matinale",
  },
};
