import { gql } from "@apollo/client";
import { NamedExoticComponent, forwardRef } from "react";

import { LetterIcon } from "@/components/LetterIcon";
import { NamedIcon } from "@/components/NamedIcon";
import type { DocumentNode } from "@/containers/Apollo";

import { Exposure } from "./article/ArticleExposureSelect";

type ExposureIconProps = {
  exposure: Exposure;
  className: string;
};

export const ExposureIcon = forwardRef<HTMLElement, ExposureIconProps>(
  ({ exposure, ...props }, ref) => {
    if (exposure.icon) {
      return (
        <NamedIcon
          ref={ref}
          name={exposure.type === "print" ? "PaperCopy" : exposure.icon}
          aria-label={exposure.label}
          {...props}
        />
      );
    }
    if (exposure.acronym) {
      return (
        // @ts-expect-error use a js component
        <LetterIcon {...props} aria-label={exposure.label}>
          {exposure.acronym}
        </LetterIcon>
      );
    }
    return null;
  },
) as NamedExoticComponent<ExposureIconProps> & {
  fragments: { exposure: DocumentNode };
};

ExposureIcon.fragments = {
  exposure: gql`
    fragment ExposureIcon_exposure on Exposure {
      id
      label
      icon
      acronym
      enabled
      type
      warnPosteriorArticlePublication
      requiredPriorArticlePublication
    }
  `,
};
