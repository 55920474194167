import clsx from "clsx";
import { useRef } from "react";
import { Tooltip } from "swash/Tooltip";
import { useHasOverflow } from "swash/utils/useHasOverflow";

export const ChangeText = (props: {
  children: React.ReactNode;
  className?: string;
  rawText?: string;
  rest: any;
}) => {
  const { children, rawText, className, ...rest } = props;
  const ref = useRef<HTMLDivElement>(null);
  const hasOverflowY = useHasOverflow({ ref, dimension: "y" });
  const hasOverflowX = useHasOverflow({ ref, dimension: "x" });
  const element = (
    <div
      ref={ref}
      className={clsx(
        className,
        "data-[change=added]:text-success-on data-[change=deleted]:text-danger-on data-[change=deleted]:line-through",
      )}
      {...rest}
    >
      {children}
    </div>
  );

  if ((rawText && hasOverflowY) || hasOverflowX) {
    return <Tooltip tooltip={rawText}>{element}</Tooltip>;
  }
  return element;
};
