import { gql } from "@apollo/client";
import { memo, useCallback } from "react";

import {
  ArticleDescription,
  ArticleDescriptionProps,
} from "@/containers/article/ArticleDescription";
import type { CellType } from "@/containers/list/ListState";
import { useSetValues } from "@/containers/search/SearchFormContext";

type ArticleDescriptionCellProps = {
  article: ArticleDescriptionProps["article"];
  showSignature: boolean;
  showEditorialLevel: boolean;
};

const ArticleDescriptionCell = memo<ArticleDescriptionCellProps>((props) => {
  const setFilters = useSetValues();
  const handleNarrowSectionClick = useCallback(
    (narrowSection: { id: number }) => {
      if (!setFilters) return;
      setFilters((filters: any) => ({
        ...filters,
        tagId: { in: [narrowSection.id] },
      }));
    },
    [setFilters],
  );

  return (
    <ArticleDescription
      article={props.article}
      onNarrowSectionClick={setFilters ? handleNarrowSectionClick : undefined}
      showSignature={props.showSignature}
      showEditorialLevel={props.showEditorialLevel}
    />
  );
});

export type DescriptionCellArticle = ArticleDescriptionProps["article"];

export const descriptionCell: CellType<DescriptionCellArticle> = {
  key: "description",
  title: "Description",
  width: "1fr",
  minWidth: 280,
  render: ({ node }) => {
    return (
      <ArticleDescriptionCell
        article={node}
        showSignature={false}
        showEditorialLevel={false}
      />
    );
  },
  fragment: gql`
    fragment descriptionCell_article on Article {
      ...ArticleDescription_article
    }

    ${ArticleDescription.fragments.article}
  `,
};

export const mediasDescriptionCell: CellType<DescriptionCellArticle> = {
  ...descriptionCell,
  render: ({ node }) => {
    return (
      <ArticleDescriptionCell
        article={node}
        showSignature={true}
        showEditorialLevel={true}
      />
    );
  },
};
