import { gql } from "@apollo/client";
import { Button } from "swash/Button";
import { Dialog } from "swash/Dialog";
import { PanelBody, PanelFooter, PanelHeader } from "swash/Panel";

import { Fieldset, FieldsetField } from "@/components/fields/Fieldset";
import { FormSavingIndicator } from "@/components/forms/FormSavingIndicator";
import { FormSubmit } from "@/components/forms/FormSubmit";
import { BooleanField, EnumField, StringField } from "@/containers/admin/CRUD";
import { UsedInSection } from "@/containers/admin/CRUD/usages/UsedInSection";
import { useUsedInResource } from "@/containers/admin/CRUD/usages/useUsedInResource";
import { ArticleFragment } from "@/containers/article/list/search";
// eslint-disable-next-line @lemonde/import/no-illegal-import
import { LivePostFragment } from "@/containers/routes/live/LivePostOperations";

import { KeywordsField } from "./KeywordsField";

const KeywordsFragment = gql`
  fragment SnippetDialog_keyword on Keyword {
    id
    label
  }
`;

const KeywordsQuery = gql`
  query SnippetAdminKeywords($search: String, $value: [Int!]) {
    connection: keywords(
      limit: 30
      where: { search: $search, id: { in: $value } }
    ) {
      nodes {
        ...SnippetDialog_keyword
      }
    }
  }
  ${KeywordsFragment}
`;

const UsedInPostsQuery = gql`
  query UsedInPostsQuery($id: Int!, $offset: Int, $limit: Int) {
    node: snippet(id: $id) {
      id
      usedIn_posts(offset: $offset, limit: $limit) {
        totalCount
        pageInfo {
          hasMore
        }
        nodes {
          id
          ...LivePostFragment
        }
      }
    }
  }

  ${LivePostFragment}
`;

const UsedInArticlesQuery = gql`
  query UsedInArticlesQuery($id: Int!, $offset: Int, $limit: Int) {
    node: snippet(id: $id) {
      id
      usedIn_articles(offset: $offset, limit: $limit) {
        totalCount
        pageInfo {
          hasMore
        }
        nodes {
          id
          ...ListSearch_article
        }
      }
    }
  }

  ${ArticleFragment}
`;

const darkModes = [
  { value: "notSupported", label: "Non supporté", displayInMenu: true },
  { value: "supported", label: "Supporté", displayInMenu: true },
];

const darkModesDescriptions = {
  notSupported: `
    Le snippet s'affiche de manière identique en mode clair et en mode sombre. Si aucune
    adaptation n'a été faite pour supporter le mode sombre, c'est généralement l'option
    conseillée.`,
  supported: `
    Le snippet a été testé et adapté pour qu'il soit compatible avec le mode sombre.
  `,
};

const usedInOperations = {
  UsedInQueries: {
    UsedInArticlesQuery,
    UsedInPostsQuery,
  },
};

export const SnippetParamsDialog = ({
  mode,
  editor,
  snippet,
  operations = usedInOperations,
  state,
}) => {
  const creation = mode === "creation";
  const title = creation ? "Créer un snippet" : "Paramètres du snippet";
  const usedInResource = useUsedInResource({
    node: snippet,
    queries: usedInOperations.UsedInQueries,
  });

  return (
    <Dialog state={state} hideOnInteractOutside={false} hideOnEscape={false}>
      <PanelHeader
        title={title}
        onClose={state.hide}
        actions={!creation && <FormSavingIndicator />}
      />
      <PanelBody>
        <Fieldset>
          <FieldsetField>
            <StringField name="title" label="Titre" required />
          </FieldsetField>
          <FieldsetField>
            <KeywordsField
              name="keywordIds"
              query={KeywordsQuery}
              fragment={KeywordsFragment}
              label="Tags"
              placeholder="Insérez un tag ici..."
              searchable
            />
          </FieldsetField>
          {!creation && (
            <>
              <FieldsetField w={200}>
                <EnumField
                  name="darkMode"
                  label="Mode sombre"
                  required
                  enum={darkModes}
                  labelElementSelector={({ value, label, displayInMenu }) => {
                    return displayInMenu ? (
                      <div className="flex items-center gap-2">
                        <div>{label}</div>
                        <span className="text-sm text-grey-on-light">
                          {darkModesDescriptions[value]}
                        </span>
                      </div>
                    ) : (
                      label
                    );
                  }}
                />
              </FieldsetField>
              <FieldsetField>
                <BooleanField name="obsolete" label="Snippet obsolète" />
              </FieldsetField>
              <div className="p-2">
                <UsedInSection
                  operations={operations}
                  label={snippet.label || snippet.title}
                  resource={snippet}
                  usedInResource={usedInResource}
                />
              </div>
            </>
          )}
        </Fieldset>
      </PanelBody>
      {creation && (
        <PanelFooter>
          <Button
            type="button"
            variant="secondary"
            appearance="text"
            onClick={() => state.hide()}
          >
            Annuler
          </Button>
          <FormSubmit onClick={() => state.hide()}>Créer</FormSubmit>
        </PanelFooter>
      )}
      {!creation && editor}
    </Dialog>
  );
};

export const SnippetCreateDialog = ({ state }) => (
  <SnippetParamsDialog mode="creation" state={state} />
);
