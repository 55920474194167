import { forwardRef } from "react";

export const LetterIcon = forwardRef(({ children, ...props }, ref) => {
  if (typeof children !== "string") return null;
  return (
    <svg ref={ref} viewBox="0 0 20 20" width="1em" height="1em" {...props}>
      <text
        x="10"
        y="16"
        textAnchor="middle"
        textLength={10 * children.length}
        fontSize="22"
        fontWeight="700"
        fill="currentColor"
        lengthAdjust="spacingAndGlyphs"
        style={{ userSelect: "none", fontFamily: "var(--font-sans)" }}
      >
        {children}
      </text>
    </svg>
  );
});
