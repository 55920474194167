/* eslint-disable graphql/template-strings */
import { gql } from "@apollo/client";
import { x } from "@xstyled/styled-components";
import { memo } from "react";
import { DialogDisclosure, useDialogState } from "swash/Dialog";
import { IoExitOutline } from "swash/Icon";
import { Link } from "swash/Link";

import { PreviewDialog } from "@/components/PreviewDialog";
import { useSafeQuery } from "@/containers/Apollo";

export { useDialogState, DialogDisclosure };

const VideoQuery = gql`
  query VideoPreview_video($videoId: Int!) {
    video(id: $videoId) {
      id
      title
      playerUrl
      url
    }
  }
`;

const Video = memo(({ video }) => {
  return (
    <x.div
      pb={5}
      style={{
        aspectRatio: "16 / 9",
        width: "auto",
        maxWidth: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      <iframe
        title={video.title}
        width="640"
        height="360"
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        src={video.playerUrl}
        style={{
          width: "100%",
          height: "100%",
        }}
      />
      <x.div pt={2} color="white" display="flex" gap={2}>
        <x.div flex="1 0 auto">{video.title}</x.div>
        <Link
          icon={<IoExitOutline />}
          className="shrink-0"
          href={video.url}
          target="_blank"
        >
          Ouvrir la vidéo
        </Link>
      </x.div>
    </x.div>
  );
});

function DialogContent({ videoId }) {
  const { data } = useSafeQuery(VideoQuery, {
    variables: { videoId },
    fetchPolicy: "cache-and-network",
  });
  if (!data) return null;
  return <Video video={data.video} />;
}

export function VideoPreviewDialog({ videoId, state }) {
  return (
    <PreviewDialog label="Prévisualisation de la vidéo" state={state}>
      <DialogContent videoId={videoId} />
    </PreviewDialog>
  );
}
