import styled from "@xstyled/styled-components";
import { forwardRef } from "react";
import { Helmet } from "react-helmet";

export const Main = styled.mainBox.attrs({ id: "main" })`
  flex: 1 0 0;
  background-color: background;
  overflow: auto;
`;

export const Header = styled.headerBox`
  padding: 0 3;
  margin: 3 0;
  display: flex;
  align-items: flex-end;
`;

export const HeaderPrimary = styled.box`
  flex: 1 1 auto;
`;

export const HeaderToolbar = styled.box`
  flex: 0 0 auto;
  margin-left: 3;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -2;
`;

export const HeaderToolbarItem = styled.box`
  flex: 0 0 auto;
  width: auto;
  padding: 0 2;
`;

export const HeaderTitle = ({ children, className = "" }) => {
  return (
    <h1 className={`${className} font-accent text-2xl font-semibold`}>
      {children}
    </h1>
  );
};

export const Section = styled.sectionBox`
  padding: 0 3 4 3;
  max-width: 1140;
  border-color: layout-border;
`;

export const SectionTitle = styled.h2Box`
  font-size: lg;
  line-height: 1.2;
  font-weight: 600;
  font-family: accent;
`;

export const StickyToolbar = styled.div`
  position: sticky;
  top: -74px;
  z-index: 1;
`;

export const BreadcrumbNav = forwardRef(({ children }, ref) => {
  return (
    <nav
      ref={ref}
      className="text-sm [&_li:first-child:last-child]:hidden [&_li:not(:last-child)]:after:mx-[1ex] [&_li:not(:last-child)]:after:content-['›'] [&_li]:mb-1 [&_li]:inline-block"
      aria-label="Breadcrumb"
    >
      {children}
    </nav>
  );
});

export const PageTitle = forwardRef(({ children, className }, ref) => {
  return (
    <>
      <HeaderTitle ref={ref} className={className}>
        {children}
      </HeaderTitle>
      {typeof children === "string" && (
        <Helmet>
          <title>{children}</title>
        </Helmet>
      )}
    </>
  );
});
