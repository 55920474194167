import {
  createContext,
  forwardRef,
  useContext,
  useMemo,
  useState,
} from "react";
import { DialogDisclosure, useDialogState } from "swash/Dialog";

export const createRemoteDialog = (DialogComponent) => {
  const RemoteDialogContext = createContext();
  const RemoteDialogProvider = ({ children, options, ...otherProps }) => {
    const state = useDialogState(options);
    const [props, setProps] = useState({});

    const value = useMemo(
      () => ({
        setProps,
        state,
      }),
      [state, setProps],
    );

    return (
      <RemoteDialogContext.Provider value={value}>
        <DialogComponent state={state} {...props} {...otherProps} />
        {children}
      </RemoteDialogContext.Provider>
    );
  };

  const RemoteDialogDisclosure = forwardRef(
    ({ dialogProps, ...props }, ref) => {
      const value = useContext(RemoteDialogContext);
      if (!value) return null;
      const { setProps, state } = value;
      return (
        <DialogDisclosure
          state={state}
          {...props}
          onClick={(event) => {
            //merge props, new props have priority
            setProps((prevProps) => ({ ...prevProps, ...dialogProps }));
            if (props.onClick) {
              props.onClick(event);
            }
          }}
          ref={ref}
        />
      );
    },
  );

  const useRemoteDialogContext = () => {
    return useContext(RemoteDialogContext);
  };

  return {
    RemoteDialogProvider,
    RemoteDialogDisclosure,
    useRemoteDialogContext,
  };
};
