import { gql } from "@apollo/client";
import { colors } from "swash/colors";

export type GetArticleStatusColorArticle = {
  initialFirstPublished: string;
  isDirty: boolean;
  lastPublishedHidden: boolean;
};

export function getArticleStatusColor(
  article: GetArticleStatusColorArticle,
): string {
  if (!article.initialFirstPublished) return colors.grey.bg.strong;
  if (article.lastPublishedHidden) return colors.danger.bg.strong;
  if (article.isDirty) return colors.warning.bg.strong;
  return colors.success.bg.strong;
}

getArticleStatusColor.fragment = gql`
  fragment getArticleStatusColor on Article {
    initialFirstPublished
    isDirty
    lastPublishedHidden
  }
`;
