import { Button } from "swash/Button";
import { Dialog } from "swash/Dialog";
import { PanelBody, PanelFooter, PanelHeader } from "swash/Panel";

import { Form } from "@/components/forms/Form";
import { FormErrorToaster } from "@/components/forms/FormError";
import { FormSubmit } from "@/components/forms/FormSubmit";

export function UninstallationDialog({ onConfirm, state }) {
  return (
    <Dialog state={state}>
      <Form
        onSubmit={async () => {
          await onConfirm();
          state.hide();
        }}
      >
        <PanelHeader
          title="Désinstaller l’intégration Facebook"
          onClose={state.hide}
        />
        <PanelBody>
          Êtes-vous sûr de vouloir désinstaller l’intégration Facebook ?
        </PanelBody>
        <PanelFooter>
          <FormErrorToaster />
          <Button
            type="button"
            variant="secondary"
            appearance="text"
            onClick={state.hide}
          >
            Annuler
          </Button>
          <FormSubmit>Désinstaller</FormSubmit>
        </PanelFooter>
      </Form>
    </Dialog>
  );
}
