import { gql } from "@apollo/client";

import { useSafeQuery } from "@/containers/Apollo";
import { CustomFields } from "@/containers/custom-fields/CustomFields";
import { CustomFieldFragment } from "@/containers/custom-fields/CustomFieldsFragments";

import { useFormPeriodical } from "../util";

const ArticleCustomFieldsQuery = gql`
  query PrintFieldsArticleCustomFieldsQuery(
    $input: GetArticleCustomFieldsInput!
  ) {
    customFields: getArticleCustomFields(input: $input) {
      ...CustomFieldFragment
    }
  }
  ${CustomFieldFragment}
`;

export function PrintCustomFields({
  data: { periodicals },
  editorialTypeId,
  disabled,
  required,
}) {
  const periodical = useFormPeriodical({ periodicals });

  const { data: { customFields = [] } = {} } = useSafeQuery(
    ArticleCustomFieldsQuery,
    {
      skip: !periodical,
      variables: {
        input: {
          scope: "periodicals",
          payload: {
            article: {
              periodicalId: periodical?.id,
              editorialTypeId,
            },
          },
        },
      },
    },
  );

  return (
    <CustomFields
      disabled={disabled}
      customFields={customFields}
      name="customFields"
      required={required}
    />
  );
}
