import { gql } from "@apollo/client";
import { Tooltip } from "swash/Tooltip";

import { EventStatusIcon } from "./EventStatusIcon";
import { EventStatusList, EventStatusListProps } from "./EventStatusList";

type EventStatus = EventStatusListProps["event"]["statuses"][number];

type Event = {
  status: "queued" | "in_progress" | "failure" | "timed_out" | "success";
  statuses: EventStatus[];
};

type StatusListIndicatorProps = {
  event: Event;
};

const StatusListIndicator = (props: StatusListIndicatorProps) => {
  return (
    <Tooltip tooltip={<EventStatusList event={props.event} />}>
      <div className="inline-flex align-[-15%]">
        <EventStatusIcon status={props.event.status} />
      </div>
    </Tooltip>
  );
};

export type EventStatusIndicatorProps = {
  event: Event;
};

export const EventStatusIndicator = (props: EventStatusIndicatorProps) => {
  if (props.event.statuses.length === 0) {
    return <EventStatusIcon status={props.event.status} />;
  }
  return <StatusListIndicator event={props.event} />;
};

EventStatusIndicator.fragments = {
  event: gql`
    fragment EventStatusIndicator_event on Event {
      status
      statuses {
        id
      }
      ...EventStatusList_event
    }

    ${EventStatusList.fragments.event}
  `,
};
