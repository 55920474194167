import { createContext, useContext, useState } from "react";

import { useConnection } from "@/containers/Ping";

const ReadOnlyContext = createContext(null);
const SetReadonlyContext = createContext(null);

export function ReadOnlyProvider({ children, readOnly: readOnlyProp }) {
  const connection = useConnection();
  const [readOnly, setReadOnly] = useState(false);

  return (
    <ReadOnlyContext.Provider
      value={readOnlyProp || readOnly || !connection.synced}
    >
      <SetReadonlyContext.Provider value={setReadOnly}>
        {children}
      </SetReadonlyContext.Provider>
    </ReadOnlyContext.Provider>
  );
}

export function useReadOnly() {
  return useContext(ReadOnlyContext);
}

export function useSetReadOnly() {
  return useContext(SetReadonlyContext);
}
