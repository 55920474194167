import { createContext, useContext } from "react";

type SetValues = (filters: any) => any;

const SetValuesContext = createContext<SetValues | null>(null);

export type SearchFormProviderProps = {
  state: { setValues: SetValues };
  children: React.ReactNode;
};

export const SearchFormProvider = (props: SearchFormProviderProps) => {
  return (
    <SetValuesContext.Provider value={props.state.setValues}>
      {props.children}
    </SetValuesContext.Provider>
  );
};

export const useSetValues = () => {
  return useContext(SetValuesContext);
};
