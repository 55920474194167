import { gql } from "@apollo/client";

import {
  GetArticleStatusColorArticle,
  getArticleStatusColor,
} from "@/containers/article/util/status";
import type { CellType } from "@/containers/list/ListState";

export type StatusCellArticle = GetArticleStatusColorArticle;

export const statusCell: CellType<StatusCellArticle> = {
  key: "status",
  title: "",
  width: 4,
  padding: 0,
  fragment: gql`
    fragment statusCell_article on Article {
      ...getArticleStatusColor
    }

    ${getArticleStatusColor.fragment}
  `,
  render: ({ node }) => {
    const color = getArticleStatusColor(node);
    return <div style={{ width: 4, height: "100%", backgroundColor: color }} />;
  },
};
