import { gql } from "@apollo/client";
import { forwardRef, useMemo } from "react";
import { Chip } from "swash/Chip";
import { IoAddCircleOutline } from "swash/Icon";

import { FieldsetField } from "@/components/fields/Fieldset";
import { useSubscribePreviousFormValues } from "@/components/forms/FormSubscribe";
import { EnumField, useNodesToEnumArray } from "@/containers/admin/CRUD";
import { useFormLayout } from "@/containers/article/util";

/** @param {{ label: string, nbCharMax: number, className?: string }} */
export const EditorialFormatLabel = ({ label, nbCharMax, className }) => {
  return (
    <div>
      <span className={className}>{label}</span>
      {nbCharMax && (
        <span className="text-xs text-grey-on"> | {nbCharMax} s.</span>
      )}
    </div>
  );
};

EditorialFormatLabel.fragments = {
  editorialFormat: gql`
    fragment EditorialFormatLabel_editorialFormat on EditorialFormat {
      id
      label
      nbCharMax
      default
    }
  `,
};

export const EditorialFormatChipButton = forwardRef(
  /** @param {{ editorialFormat: object | null, asButton: boolean, onFocus?: function, onMouseEnter?: function }} */
  ({ editorialFormat, asButton = false, ...props }, ref) => {
    return (
      <Chip ref={ref} asButton={asButton} {...props}>
        {editorialFormat ? (
          <EditorialFormatLabel
            label={editorialFormat.label}
            className="text-xs uppercase leading-none"
          />
        ) : (
          <>
            <IoAddCircleOutline className="text-grey-on" />
            <span className="text-xs text-grey-on">FORMAT</span>
          </>
        )}
      </Chip>
    );
  },
);

export const useEditorialFormats = ({ layout }) => {
  return useMemo(() => layout?.editorialFormats?.nodes ?? [], [layout]);
};

export const EditorialFormatField = forwardRef(
  ({ layouts, disabled, ...props }, ref) => {
    const layout = useFormLayout({ layouts });
    const editorialFormats = useEditorialFormats({ layout });

    const enumValues = useNodesToEnumArray(editorialFormats);

    // Reset editorial type when a new layout is selected
    useSubscribePreviousFormValues(({ values, previousValues, form }) => {
      if (values.layoutId !== previousValues.layoutId) {
        form.change("editorialFormatId", null);
      }
    });

    if (!layout) return null;
    if (!editorialFormats.length) return null;

    return (
      <FieldsetField {...props} ref={ref}>
        <EnumField
          name="editorialFormatId"
          label="Format"
          placeholder="Sélectionnez un format"
          enum={enumValues}
          clearable
          sortEntries={() => 1}
          labelSelector={(props) => (
            <EditorialFormatLabel
              label={props.label}
              nbCharMax={props.nbCharMax}
            />
          )}
          disabled={disabled}
        />
      </FieldsetField>
    );
  },
);

EditorialFormatField.fragments = {
  layouts: gql`
    fragment LayoutField_layoutEditorialFormat on Layout {
      id
      editorialFormats(limit: 100) {
        nodes {
          id
          ...EditorialFormatLabel_editorialFormat
        }
      }
    }
    ${EditorialFormatLabel.fragments.editorialFormat}
  `,
};
