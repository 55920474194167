import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useReadOnly, useSetReadOnly } from "../ReadOnly";

const CompareContext = createContext();
const SetCompareContext = createContext();

const CompareVersionsValuesContext = createContext();

const useComparedModeReadOnly = (compare) => {
  const setReadOnly = useSetReadOnly();
  const readOnly = useReadOnly();
  const readOnlyRef = useRef();

  useEffect(() => {
    if (compare.enabled && compare.featureOpened) {
      readOnlyRef.current = readOnly;
      setReadOnly(true);
    }
    if (readOnlyRef.current && (!compare.enabled || !compare.featureOpened)) {
      readOnlyRef.current = null;
      setReadOnly(readOnlyRef.current);
    }
    return () => {
      if (readOnlyRef.current && (!compare.enabled || !compare.featureOpened)) {
        readOnlyRef.current = null;
        setReadOnly(readOnlyRef.current);
      }
    };
  }, [compare.enabled, compare.featureOpened, readOnly, setReadOnly]);
};

export const CompareVersionsProvider = ({ children }) => {
  const [compare, setCompare] = useState({
    enabled: true,
    featureOpened: false,
    count: null,
  });
  const [selectedRevision, setSelectedRevision] = useState();
  const [pinnedRevision, setPinnedRevision] = useState();

  useComparedModeReadOnly(compare);

  const value = useMemo(
    () => ({
      selectedRevision,
      setSelectedRevision,
      pinnedRevision,
      setPinnedRevision,
    }),
    [selectedRevision, setSelectedRevision, pinnedRevision, setPinnedRevision],
  );

  return (
    <CompareContext.Provider value={compare}>
      <SetCompareContext.Provider value={setCompare}>
        <CompareVersionsValuesContext.Provider value={value}>
          {children}
        </CompareVersionsValuesContext.Provider>
      </SetCompareContext.Provider>
    </CompareContext.Provider>
  );
};

export const useCompare = () => useContext(CompareContext);
export function useSetCompare() {
  return useContext(SetCompareContext);
}

export const useCompareVersionsValuesContext = () =>
  useContext(CompareVersionsValuesContext);
