import { FORM_ERROR } from "final-form";
import { get, uniq } from "lodash-es";
import { forwardRef } from "react";

import { CollaborativeForm } from "@/components/forms/CollaborativeForm";
import { ERRORS } from "@/config/messages";

import { BaseForm } from "./BaseForm";
import { FormSubmitterProvider } from "./FormSubmitter";

export { FORM_ERROR } from "final-form";

function getGraphQLErrorFromCode(errorCode, params) {
  const rawError = get(ERRORS, errorCode.split(":"));
  if (!rawError) return null;
  if (typeof rawError === "string") {
    return { [FORM_ERROR]: rawError };
  }
  if (typeof rawError === "function") {
    return { [FORM_ERROR]: rawError(params) };
  }
  return rawError;
}

export function extractGraphQlErrors(error) {
  const graphQLErrors = error?.graphQLErrors;
  if (!graphQLErrors) return {};

  return graphQLErrors.reduce((obj, error) => {
    const errorCode =
      (error?.extensions?.exception?.code || error?.extensions?.code) ??
      "default";
    const gqlError = getGraphQLErrorFromCode(errorCode, error?.extensions);
    if (!gqlError) return obj;
    return Object.entries(gqlError).reduce(
      (obj, [field, value]) => ({
        ...obj,
        [field]: uniq([...(obj[field] || []), value]),
      }),
      obj,
    );
  }, {});
}

/** @type {React.FC<{ autoComplete?: string } & import('react-final-form').FormProps>} */
export const Form = forwardRef(
  (
    {
      children,
      onSubmit,
      name,
      "aria-label": ariaLabel,
      collaborative,
      as: As = "form",
      initialValues,
      initialValuesEqual,
      keepDirtyOnReinitialize,
      mutators,
      decorators,
      validate,
      destroyOnUnregister,
      ...props
    },
    ref,
  ) => {
    if (process.env["NODE_ENV"] === "development") {
      if (typeof onSubmit !== "function") {
        // eslint-disable-next-line no-console
        console.warn(`onSubmit is required in Form`);
      }
    }

    const Form = collaborative ? CollaborativeForm : BaseForm;
    const formProps = collaborative === "readOnly" ? { readOnly: true } : {};

    const handleSubmit = async (values, form, infos) => {
      try {
        return await onSubmit(values, form, infos);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        const graphQLErrors = extractGraphQlErrors(error);

        return Object.keys(graphQLErrors).length === 0
          ? { [FORM_ERROR]: "Une erreur est survenue" }
          : graphQLErrors;
      }
    };

    return (
      <FormSubmitterProvider>
        <Form
          {...formProps}
          onSubmit={handleSubmit}
          name={name}
          initialValues={initialValues}
          initialValuesEqual={initialValuesEqual}
          keepDirtyOnReinitialize={keepDirtyOnReinitialize}
          destroyOnUnregister={destroyOnUnregister}
          mutators={mutators}
          decorators={decorators}
          validate={validate}
        >
          {(formRenderProps) => {
            const rendered =
              typeof children === "function"
                ? children(formRenderProps)
                : children;

            if (As === null) return rendered;

            const elementProps =
              As === "form"
                ? {
                    noValidate: true,
                    onSubmit: formRenderProps.handleSubmit,
                  }
                : { role: "form" };

            return (
              <As
                ref={ref}
                name={name}
                aria-label={ariaLabel}
                style={{ display: "contents", ...props.style }}
                {...elementProps}
                {...props}
              >
                {rendered}
              </As>
            );
          }}
        </Form>
      </FormSubmitterProvider>
    );
  },
);
