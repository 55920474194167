import * as React from "react";
import { forwardRef } from "react";

const SvgLineCheck = (props, ref) => (
  <svg
    width={20}
    height={6}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path fill="#2B3C50" d="M0 2h10l2 2H0V2Z" />
    <path
      stroke="#32AD81"
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M14 2.125 15.923 4 19 1"
    />
  </svg>
);
const ForwardRef = forwardRef(SvgLineCheck);
export default ForwardRef;
