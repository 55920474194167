import styled from "@xstyled/styled-components";
import { forwardRef } from "react";

import { SectionTitle } from "@/components/Layout";

const InnerFieldset = styled.box`
  margin: -2;
`;

export const Fieldset = forwardRef(({ layout = "vertical", ...props }, ref) => {
  return <InnerFieldset ref={ref} data-layout={layout} {...props} />;
});

export const FieldsetField = styled.box`
  padding: 2;
`;

export const FieldsetTitle = styled(SectionTitle)`
  margin: 2;
  &:not(:first-child) {
    margin-top: 4;
  }
`;
