/* eslint-disable react/prop-types */
import { x } from "@xstyled/styled-components";
import { forwardRef, memo, useEffect } from "react";
import { Loader } from "swash/Loader";
import { useLiveRef } from "swash/utils/useLiveRef";
import useIsInViewport from "use-is-in-viewport";

export function useInfiniteScrollState({ hasMore, loadMore, loading }) {
  const [endMarkerInViewport, targetRef] = useIsInViewport();

  const loadMoreRef = useLiveRef(loadMore);
  useEffect(() => {
    if (hasMore && endMarkerInViewport && !loading) {
      loadMoreRef.current();
    }
  }, [endMarkerInViewport, hasMore, loading, loadMoreRef]);

  return {
    state: {
      targetRef,
      endMarkerInViewport,
      hasMore,
      loadMore,
      loading,
    },
  };
}

export function InfiniteScrollMarker({
  state: { targetRef },
  as: As = "div",
  children,
  ...props
}) {
  return <As ref={targetRef} {...props} className="pb-px" />;
}

export const InfiniteScrollLoader = forwardRef(
  ({ state: { loading } }, ref) => {
    if (!loading) return null;
    return (
      <div className="flex items-center justify-center p-4">
        <Loader ref={ref} size={16} />
      </div>
    );
  },
);

export const InfiniteScrollEnd = forwardRef(
  ({ state: { hasMore, loading }, ...props }, ref) => {
    return !hasMore && !loading ? (
      <x.div
        textAlign="center"
        color="grey-on"
        fontFamily="accent"
        text="xs"
        mb={4}
        ref={ref}
        {...props}
      >
        Fin des résultats
      </x.div>
    ) : null;
  },
);

export const SimpleInfiniteScroll = memo(
  ({ hasMore, loadMore, loading, marker = true, loader = true }) => {
    const infiniteScroll = useInfiniteScrollState({
      hasMore,
      loadMore,
      loading,
    });

    return (
      <>
        {marker && <InfiniteScrollMarker {...infiniteScroll} />}
        {loader && <InfiniteScrollLoader {...infiniteScroll} />}
        <InfiniteScrollEnd {...infiniteScroll} />
      </>
    );
  },
);
