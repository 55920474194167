import { gql } from "@apollo/client";
import clsx from "clsx";
import { useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import { Button } from "swash/Button";
import { IoEyeOff } from "swash/Icon";
import { Tooltip } from "swash/Tooltip";
import { useResizeObserver } from "swash/utils/useResizeObserver";

import { useHasExperimentalFeature, useUser } from "@/containers/User";

import {
  useAuthorizedGraphicContent,
  useSetAuthorizedGraphicContent,
} from "./ImageGraphicContentContext";

type Image = {
  id: number;
  graphicContent: boolean;
};

type ImageGraphicContentGuardScale = "sm" | "md" | "lg";

const scales: Record<ImageGraphicContentGuardScale, string> = {
  lg: /* tw */ `blur-xl`,
  md: /* tw */ `blur-md`,
  sm: /* tw */ `blur-sm`,
};

type ImageGraphicContentGuardProps = {
  children: React.ReactNode;
  image: Image;
  scale?: ImageGraphicContentGuardScale;
};

const message =
  "Cette image a été identifiée comme comportant du contenu sensible que certaines personnes pourraient trouver offensant ou dérangeant.";

export const ImageGraphicContentGuard = ({
  image,
  children,
  scale = "lg",
}: ImageGraphicContentGuardProps) => {
  const isImageAuthorized = useAuthorizedGraphicContent();
  const authorizeImage = useSetAuthorizedGraphicContent();
  const hasGraphicContentFeature = useHasExperimentalFeature("graphic-content");
  const user = useUser();
  const tooltip = useMemo(() => (scale === "sm" ? message : null), [scale]);

  const [loading, setLoading] = useState(true);
  const containerRef = useResizeObserver((entry) => {
    const { clientWidth, clientHeight } = entry.target;
    setLoading(!(clientWidth > 0 && clientHeight > 0));
  });

  if (!hasGraphicContentFeature) return children;
  if (!user.settings.imageHideGraphicContent) return children;
  if (!image?.graphicContent) return children;
  if (isImageAuthorized(image.id)) return children;

  return (
    <div
      className={clsx("relative h-full", loading && "invisible")}
      ref={containerRef}
    >
      <div className="absolute z-[25] flex h-full w-full flex-col items-center justify-center gap-4 bg-black bg-opacity-40 text-sm text-white">
        <Tooltip tooltip={tooltip}>
          <div>
            <IoEyeOff className={scale === "sm" ? "h-5 w-5" : "h-6 w-6"} />
          </div>
        </Tooltip>
        {["md", "lg"].includes(scale) && (
          <div className="flex flex-col items-center gap-2 text-center">
            <div>
              <strong className="font-accent text-base">
                Contenu sensible
              </strong>
            </div>
            {scale === "lg" && <div className="w-4/5">{message}</div>}
          </div>
        )}
        {scale === "lg" && (
          <>
            <Button
              variant="secondary"
              className="bg-opacity-20 font-accent"
              onClick={() => authorizeImage(image.id)}
            >
              Afficher l’image
            </Button>
            <div className="absolute bottom-4 text-center text-xs">
              Pour toujours voir ces contenus, vous pouvez{" "}
              <span className="cursor-pointer underline">
                <NavLink to="/account-settings/preferences/image">
                  modifier vos préférences utilisateur
                </NavLink>
              </span>
            </div>
          </>
        )}
      </div>
      <div className="h-full overflow-hidden">
        <div className={scales[scale]}>{children}</div>
      </div>
    </div>
  );
};

ImageGraphicContentGuard.fragments = {
  image: gql`
    fragment ImageGraphicContentGuard_image on Image {
      id
      graphicContent
    }
  `,
};
