import { DocumentNode, useApolloClient } from "@apollo/client";
import type { DraftHandleValue } from "draft-js-es";
import * as React from "react";
import {
  MouseEventHandler,
  ReactElement,
  RefObject,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { useEventCallback } from "swash/utils/useEventCallback";
import { useLiveRef } from "swash/utils/useLiveRef";
import { usePrevious } from "swash/utils/usePrevious";
import {
  ComboboxItem,
  ComboboxPopover,
  ComboboxStoreProps,
  useComboboxStore,
} from "swash/v2/Combobox";
import { MenuComboboxList } from "swash/v2/MenuCombobox";
import {
  RemoteSelectListProps,
  RemoteSelectState,
  useRemoteSelectList,
  useRemoteSelectState,
} from "swash/v2/RemoteSelect";

import {
  MentionContext,
  useMention,
  useMentionPluginConfig,
} from "@/components/rich-editor/plugins/mention/MentionPluginContext";
import { useMentionRemoteSelect } from "@/components/rich-editor/plugins/mention/MentionRemoteSelectProvider";
import type {
  MentionData,
  MentionPluginProps,
} from "@/components/rich-editor/plugins/mention/index";
import addMention from "@/components/rich-editor/plugins/mention/modifiers/addMention";
import { mergeConnections, useSafeQuery } from "@/containers/Apollo";
import { UserAvatar, UserAvatarUser } from "@/containers/user/UserAvatar";
import {
  UserHoverCardTooltip,
  UserHoverCardUser,
} from "@/containers/user/UserHoverCard";

type MentionQueryData = {
  mentions: {
    pageInfo: {
      hasMore: boolean;
    };
    totalCount: number;
    nodes: MentionData[];
  };
};

type MentionQueryVariables = {
  search: string;
  [x: string]: any;
};

interface MentionRemoteSelectListProps<TItem>
  extends Omit<RemoteSelectListProps<TItem>, "placeholder"> {
  onItemClick(item: TItem): MouseEventHandler<HTMLDivElement> | undefined;
}

type MentionSelectValueProps = {
  icon?: React.ReactNode;
  label: string | React.ReactNode;
};

const MentionSelectValue = forwardRef(
  ({ icon, label }: MentionSelectValueProps, ref) => (
    <div
      className="flex w-full items-center gap-2 py-1"
      ref={ref as RefObject<HTMLDivElement>}
    >
      {icon}
      <span className="overflow-hidden text-ellipsis whitespace-nowrap text-sm">
        {label}
      </span>
    </div>
  ),
);

const MentionRemoteSelectList = <TItem extends MentionData>(
  props: MentionRemoteSelectListProps<TItem>,
): ReactElement => {
  const {
    state: { combobox, valueSelector, iconSelector, labelSelector },
    onItemClick,
  } = props;

  const { listRef, virtualItems, display, rowVirtualizer } =
    useRemoteSelectList(props);

  return (
    <MenuComboboxList
      ref={listRef}
      store={combobox}
      className="z-popover max-h-72 w-60 border-t-0"
    >
      {virtualItems.length > 0 && (
        <div
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`,
            width: "100%",
            position: "relative",
          }}
        >
          {virtualItems.map((virtualItem) => {
            const item = display.unselected[virtualItem.index];
            if (!item) return null;
            const value = valueSelector(item);
            const icon = iconSelector(item);
            const label = labelSelector(item);

            return (
              <ComboboxItem
                key={virtualItem.key}
                value={value}
                focusOnHover
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: `${virtualItem.size}px`,
                  transform: `translateY(${virtualItem.start}px)`,
                  zIndex: 3000,
                }}
                onMouseDown={onItemClick(item)}
              >
                {(() => {
                  switch (item["__typename"]) {
                    case "User":
                      return (
                        <UserHoverCardTooltip
                          user={item as unknown as UserHoverCardUser}
                          placement="left"
                          gutter={16}
                        >
                          <MentionSelectValue icon={icon} label={label} />
                        </UserHoverCardTooltip>
                      );
                    default:
                      return <MentionSelectValue icon={icon} label={label} />;
                  }
                })()}
              </ComboboxItem>
            );
          })}
        </div>
      )}
    </MenuComboboxList>
  );
};

export type TriggerMentionQuery = {
  query: DocumentNode;
  mentionFragment: DocumentNode;
  mentionType: string;
  fragmentName?: string;
  variables?: {
    [x: string]: any;
  };
};

interface MentionSelectStateProps extends ComboboxStoreProps {
  searchValue: string;
  triggerMentionQuery: TriggerMentionQuery;
}

export const useMentionSelectState = ({
  searchValue,
  triggerMentionQuery,
  ...props
}: MentionSelectStateProps): RemoteSelectState<MentionData> => {
  const combobox = useComboboxStore({
    ...props,
    value: searchValue ?? "",
    placement: "top-start",
  });
  const search = combobox.useState("value");
  const client = useApolloClient();
  const queryResult = useSafeQuery<MentionQueryData, MentionQueryVariables>(
    triggerMentionQuery.query,
    {
      variables: {
        search,
        ...triggerMentionQuery.variables,
      },
    },
  );
  const data = queryResult?.data ?? queryResult?.previousData;

  return useRemoteSelectState({
    value: null,
    onChange: () => undefined,
    data: data
      ? {
          items: data.mentions.nodes,
          hasMore: data.mentions.pageInfo.hasMore ?? false,
          totalCount: data.mentions.totalCount ?? 0,
        }
      : null,
    combobox,
    loading: queryResult?.loading ?? false,
    fetchMore: (previousData) => {
      queryResult?.fetchMore({
        variables: { offset: previousData.items.length },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!previousResult.mentions) return previousResult;
          return {
            ...previousResult,
            mentions: mergeConnections(
              previousResult.mentions,
              fetchMoreResult.mentions,
            ),
          };
        },
      });
    },
    getItem: (id) => {
      const value = client.readFragment({
        id: `${triggerMentionQuery.mentionType}:${id}`,
        fragment: triggerMentionQuery.mentionFragment,
        fragmentName: triggerMentionQuery.fragmentName,
      });
      if (!value) {
        throw new Error("No mention found");
      }
      return value;
    },
    labelSelector: (mention) => mention.name,
    iconSelector: (mention) =>
      mention["__typename"] === "User" && (
        <UserAvatar user={mention as unknown as UserAvatarUser} size={14} />
      ),
    valueSelector: (mention) => mention.id.toString(),
  });
};

export interface MentionSuggestionsSelectState
  extends Omit<
    MentionSuggestionsSelectProps,
    "searchValue" | "activeTrigger" | "activeOffsetKey" | "triggerMentionQuery"
  > {
  state: RemoteSelectState<MentionData>;
  hasMatches: boolean;
  show: () => void;
  hide: () => void;
  isOpen: () => boolean;
  onItemClick: MentionRemoteSelectListProps<MentionData>["onItemClick"];
}

export type MentionSuggestionsSelectProps = Omit<
  MentionPluginProps,
  "isMentionOpen"
> &
  Omit<MentionRemoteSelectListProps<MentionData>, "onItemClick" | "state"> &
  ComboboxStoreProps & {
    searchValue: string;
    activeTrigger: string;
    activeOffsetKey: string;
    getAnchorRect: () => DOMRect | null;
    triggerMentionQuery: TriggerMentionQuery;
  };

type MentionSuggestionsSelectRefs = Pick<
  MentionContext,
  "getEditor" | "escapeSearch"
> &
  Pick<MentionSuggestionsSelectProps, "activeOffsetKey" | "activeTrigger">;

const scrollToNext = (next: string | undefined | null) => {
  // unable to focus element to trigger the scroll
  // dummy way
  if (next) {
    const item = document.getElementById(next);
    item?.scrollIntoView({
      block: "start",
      behavior: "auto",
    });
  }
};

export const useMentionSuggestionsSelect = (
  props: MentionSuggestionsSelectProps,
): MentionSuggestionsSelectState => {
  const { escapeSearch, getEditor } = useMention();
  const { mentionPrefix, entityMutability } = useMentionPluginConfig();

  const refs = useRef<MentionSuggestionsSelectRefs>({
    activeOffsetKey: props.activeOffsetKey,
    activeTrigger: props.activeTrigger,
    getEditor,
    escapeSearch,
  });

  const state = useMentionSelectState(props);

  const activeId = state.combobox.useState("activeId");
  const items = state.combobox.useState("items");
  const renderedItems = state.combobox.useState("renderedItems");

  const previousActiveId = usePrevious(activeId);

  const hasMatches = !!items.length;

  useEffect(() => {
    // active item on open or on mouse moveType
    if (renderedItems.length && !activeId) {
      state.combobox.move(previousActiveId ?? state.combobox.first());
    }
  }, [renderedItems, previousActiveId, state.combobox, activeId]);

  const stateRef = useLiveRef(state);
  const selectRef = useLiveRef(state.combobox);

  const commitSelection = useCallback(
    (mention: MentionData | null): DraftHandleValue => {
      selectRef.current.hide();
      if (!mention) return "not-handled";
      const { getEditor, activeTrigger } = refs.current;
      const { getEditorState, setEditorState } = getEditor();
      selectRef.current.setActiveId(undefined);
      const newEditorState = addMention(
        getEditorState(),
        mention,
        mentionPrefix,
        activeTrigger || "",
        entityMutability,
      );
      setEditorState(newEditorState);
      return "handled";
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onDownArrow = useEventCallback((event: KeyboardEvent) => {
    event.preventDefault();
    const next = selectRef.current.down();
    selectRef.current.move(next);
    scrollToNext(next);
  });
  const onUpArrow = useEventCallback((event: KeyboardEvent) => {
    event.preventDefault();
    const next = selectRef.current.up();
    selectRef.current.move(next);
    scrollToNext(next);
  });
  const onEscape = useEventCallback((event: KeyboardEvent) => {
    const { activeOffsetKey } = refs.current;
    if (!activeOffsetKey) return;
    event.preventDefault();
    escapeSearch(activeOffsetKey);
    selectRef.current.hide();
  });

  const onMove = useEventCallback(() => {
    selectRef.current.hide();
  });

  const getActiveMention = useCallback((): MentionData | null => {
    //activeValue is not updated when items are updated (ariakit 0.3.8)
    const state = stateRef.current.combobox.getState();
    const activeId = state.activeId;
    const activeValue = state.items.find((i) => i.id === activeId)?.value;
    if (activeValue) {
      return stateRef.current.getItem(activeValue);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onTab = useCallback((event: KeyboardEvent) => {
    event.preventDefault();
    commitSelection(getActiveMention());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReturn = () => {
    return commitSelection(getActiveMention());
  };

  const onItemClick: MentionRemoteSelectListProps<MentionData>["onItemClick"] =
    (value) => (event) => {
      event.preventDefault();
      commitSelection(value);
    };

  return {
    state,
    hasMatches,
    onItemClick,
    onDownArrow,
    onUpArrow,
    onEscape,
    onMove,
    onTab,
    getAnchorRect: props.getAnchorRect,
    handleReturn,
    show: selectRef.current.show,
    hide: selectRef.current.hide,
    isOpen: () => selectRef.current.getState().open,
  };
};

export const MentionSuggestionsSelect = memo(
  (props: MentionSuggestionsSelectProps) => {
    const select = useMentionSuggestionsSelect(props);
    const open = select.state.combobox.useState("open");
    const openRef = useLiveRef(open);
    const selectRef = useLiveRef(select);
    const { registerMentionSelectState } = useMentionRemoteSelect();
    const { isEscaped } = useMention();

    useEffect(() => {
      return registerMentionSelectState(selectRef.current);
    }, [registerMentionSelectState, selectRef]);

    useEffect(() => {
      if (!openRef.current && !isEscaped(props.activeOffsetKey || "")) {
        selectRef.current.state.select.show();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEscaped, props.activeOffsetKey]);

    return (
      <ComboboxPopover
        hidden={!select.hasMatches}
        store={select.state.combobox}
        // prevent scroll
        preventBodyScroll={true}
        backdrop={true}
        portal
        getAnchorRect={select.getAnchorRect}
        className="p-0"
      >
        <MentionRemoteSelectList
          state={select.state}
          onItemClick={select.onItemClick}
        />
      </ComboboxPopover>
    );
  },
);
