import { PanelBody } from "swash/Panel";

import { createPanel } from "@/containers/PanelManager";
import { useRemoteConfig } from "@/containers/RemoteConfig";
import { TrackPanel } from "@/containers/article/ArticleMetaPanel";
import { StandaloneArticlePanelHeader } from "@/containers/article/ArticlePanelHeader";
import { ArticleExposureForm } from "@/containers/article/panels/exposure/ArticleExposureForm";
import { ArticleLightAlertSection } from "@/containers/article/panels/exposure/alert-section/ArticleLightAlertSection";
import { ArticleEventDispatchers } from "@/containers/article/panels/exposure/event-dispatchers-section/ArticleEventDispatchers";
import { ArticleExportPrintSection } from "@/containers/article/panels/exposure/print-section/ArticleExportPrintSection";

const ArticleExposurePanelPrintSection = ({ articleId }) => {
  const { hasPrintSystem } = useRemoteConfig();

  if (!hasPrintSystem) return null;

  return <ArticleExportPrintSection articleId={articleId} inFloatingPanel />;
};

export const ArticleExposurePanel = createPanel({
  key: "article-exposure",
  title: "Exposition",
  render: ({ articleId, onClose }) => (
    <>
      <TrackPanel panelKey="article-exposure" />
      <StandaloneArticlePanelHeader articleId={articleId} onClose={onClose} />
      <PanelBody data-testid="panel-body-exposure">
        <ArticleExposureForm articleId={articleId} />
        <ArticleLightAlertSection articleId={articleId} />
        <ArticleExposurePanelPrintSection articleId={articleId} />
        <ArticleEventDispatchers articleId={articleId} />
      </PanelBody>
    </>
  ),
});
