import { useEffect } from "react";
import { PanelBody } from "swash/Panel";

import { useAmplitude } from "@/containers/Amplitude";
import { createPanel } from "@/containers/PanelManager";
import { useHasExperimentalFeature } from "@/containers/User";
import { TrackPanel } from "@/containers/article/ArticleMetaPanel";
import { StandaloneArticlePanelHeader } from "@/containers/article/ArticlePanelHeader";

import { ForecastAnalytics } from "./Forecast";
import { ForecastInsights } from "./ForecastInsights";
import { SiriusAnalytics } from "./Sirius";
import { SiriusAnalyticsV2 } from "./SiriusV2";
import { TogglerProvider, useToggleAnalyticsState } from "./TogglerProvider";

export const ArticleAnalyticsPanelBody = ({ articleId }) => {
  const toggledAnalyticsState = useToggleAnalyticsState();
  const hasForecastInsights = useHasExperimentalFeature(
    "forecast-analytics-charts",
  );
  const hasSiriusAnalyticsV2 = useHasExperimentalFeature("sirius-analytics-v2");

  const { logEvent } = useAmplitude();
  useEffect(() => {
    logEvent("statsPanel:open");
  }, [logEvent, articleId]);

  return (
    <TogglerProvider state={toggledAnalyticsState}>
      <PanelBody className="flex flex-col gap-4">
        {hasSiriusAnalyticsV2 ? (
          <SiriusAnalyticsV2 articleId={articleId} />
        ) : (
          <SiriusAnalytics articleId={articleId} />
        )}
        {hasForecastInsights ? (
          <ForecastInsights articleId={articleId} />
        ) : (
          <ForecastAnalytics articleId={articleId} />
        )}
      </PanelBody>
    </TogglerProvider>
  );
};

const PanelContent = ({ articleId, onClose }) => {
  return (
    <>
      <StandaloneArticlePanelHeader articleId={articleId} onClose={onClose} />
      <ArticleAnalyticsPanelBody articleId={articleId} />
    </>
  );
};

export const ArticleAnalyticsPanel = createPanel({
  key: "article-analytics",
  title: "Statistiques",
  render: ({ articleId, onClose }) => {
    return (
      <>
        <TrackPanel panelKey="article-analytics" />
        <PanelContent articleId={articleId} onClose={onClose} />
      </>
    );
  },
});
