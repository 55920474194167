import styled from "@xstyled/styled-components";
import { forwardRef, memo } from "react";
import { IoSparkles } from "swash/Icon";
import { Tooltip } from "swash/Tooltip";

import Close from "@/assets/imgs/inline-svg/close.svg";
import { Image } from "@/components/Image";

const InnerEditorNodeCard = styled.box`
  background-color: grey-lightest;
  user-select: none;

  &[data-border-colored="false"] {
    border-radius: 4;
  }

  &[data-variant="danger"] {
    background-color: danger-lightest;
  }

  &[data-variant="valid"] {
    background-color: white;
  }

  &[data-display-mode="preview"] & {
    background-color: grey-lighter;
  }

  &[data-display-mode="compare"] & {
    background-color: grey-lightest;
  }

  &[data-expanded] {
    background-color: grey-lightest;
    padding: 4;

    &[data-variant="side-panel"] {
      background-color: white;
      padding: 0;
    }

    &[data-editor-block-capsule-sidebar] {
      background-color: green;
    }
    &[readonly] {
      background-color: yellow;
    }
  }

  &[data-expanded] [data-editor-block-capsule-body] {
    padding-left: 0;
    padding-right: 0;
  }

  &[data-expanded] [data-editor-block-capsule-sidebar] {
    width: 0;
    padding: 0;
  }

  &[data-expanded] [data-border-colored="false"] {
    border: solid 1px;
    border-color: grey-lighter;
  }

  &[data-border-colored="true"] {
    border-left-width: 3;
  }

  &[data-border-colored="false"] {
    padding-left: 3px;
  }

  &[data-expanded] [data-border-colored="true"] {
    border-top-color: grey-lighter;
    border-top-width: 1;
    border-right-color: grey-lighter;
    border-right-width: 1;
    border-bottom-color: grey-lighter;
    border-bottom-width: 1;
    border-left-width: 4;
    border-radius: 4;
  }

  [data-change="deleted"] &:not([data-expanded]) {
    background-color: change-deleted-bg;
    color: change-deleted;
  }
  [data-change="added"] &:not([data-expanded]) {
    background-color: change-added-bg;
    color: change-added;
  }
  [data-change="deleted"] &[data-expanded] {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      inset: 0 0 0 0;
      background-color: change-deleted-media;
      opacity: 0.25;
    }
    &:after {
      content: "";
      mask-image: url(${Close});
      position: absolute;
      inset: 0 0 0 0;
      background-color: white;
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: 48px;
      z-index: 1;
    }
  }

  [data-change="added"] &[data-expanded] {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      inset: 0 0 0 0;
      background-color: change-added-media;
      opacity: 0.25;
    }
  }
`;

export const EditorNodeCard = memo(
  forwardRef(({ borderColor, variant, expanded, ...props }, ref) => {
    return (
      <InnerEditorNodeCard
        ref={ref}
        borderLeftColor={borderColor}
        data-variant={variant}
        data-expanded={expanded ? "" : undefined}
        data-border-colored={Boolean(borderColor)}
        {...props}
      />
    );
  }),
);

export const EditorNodeLayout = (props) => (
  <div
    className="flex items-center gap-2 p-2 text-sm text-dusk-on"
    {...props}
  />
);

export const EditorNodeIcon = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-right: 1;
  gap: 8px;

  [data-variant="loading"] & {
    opacity: 0.38;
  }

  > svg {
    width: 20;
    height: 20;
  }

  [data-change="deleted"] & {
    color: change-deleted;
  }
  [data-change="added"] & {
    color: change-added;
  }
`;

export const EditorNodeGenAIIcon = () => {
  return (
    <IoSparkles
      role="status"
      aria-label="Recommandé par l’assistant"
      className="!h-3.5 !w-3.5 text-purple-on"
    />
  );
};

const InnerEditorNodeImage = styled.div`
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;

  [data-variant="loading"] & {
    opacity: 0.38;
  }

  [data-change="deleted"] & {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      inset: 0 0 0 0;
      background-color: change-deleted-media;
      opacity: 0.25;
    }
    &:after {
      content: "";
      mask-image: url(${Close});
      position: absolute;
      inset: 0 0 0 0;
      background-color: white;
      mask-repeat: no-repeat;
      mask-position: center;
      mask-size: 16px;
    }
  }
  [data-change="deleted"] [data-expanded] & {
    &:after {
      mask-size: 48px;
    }
  }

  [data-change="added"] & {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      inset: 0 0 0 0;
      background-color: change-added-media;
      opacity: 0.25;
    }
  }
`;

export const EditorNodeImage = ({
  src,
  width: widthProp,
  height: heightProp,
  loading = false,
  expanded,
  ...props
}) => {
  const width = expanded ? 1 : widthProp ?? 72;
  const height = expanded ? "auto" : heightProp ?? 52;

  return (
    <InnerEditorNodeImage data-variant={loading ? "loading" : undefined}>
      {!loading && (
        <Image
          src={src}
          {...props}
          className="pointer-events-none overflow-hidden object-contain"
          style={props.style}
          w={width}
          maxH="3000px"
          maxW="none"
          h={height}
          flexShrink="0"
        />
      )}
    </InnerEditorNodeImage>
  );
};

const EditorNodeBodyContainer = styled.box`
  flex: 1 1 auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  > * {
    line-height: initial;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &[data-multiline] {
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    > * {
      white-space: normal;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  [data-variant="loading"] & {
    opacity: 0.38;
  }
`;

export const EditorNodeBody = ({ multiline, variant, children }) => {
  return (
    <EditorNodeBodyContainer
      data-variant={variant}
      data-multiline={multiline ? "" : undefined}
    >
      {children}
    </EditorNodeBodyContainer>
  );
};

export const EditorNodeTooltip = ({ tooltip, children }) => {
  return tooltip ? (
    <Tooltip tooltip={tooltip} placement="left">
      <div>{children}</div>
    </Tooltip>
  ) : (
    children
  );
};
