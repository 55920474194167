import { gql } from "@apollo/client";
import { memo } from "react";
import { DialogDisclosure, useDialogState } from "swash/Dialog";
import { IoPencil, ZipFile } from "swash/Icon";

import {
  EditorBlockCapsuleToolbarActions,
  EditorBlockCapsuleToolbarButton,
} from "@/components/teleporters/EditorBlockCapsule";
import { ArchiveEditDialog } from "@/containers/archive/ArchiveEditDialog";

import { ChangeText } from "./ChangeText";
import {
  EditorNodeBody,
  EditorNodeCard,
  EditorNodeIcon,
  EditorNodeLayout,
  EditorNodeTooltip,
} from "./NodeLayout";

const EditArchiveAction = memo(({ archive }) => {
  const editDialog = useDialogState();

  return (
    <>
      <DialogDisclosure title="Éditer l’archive" state={editDialog}>
        {(disclosureProps) => (
          <EditorBlockCapsuleToolbarButton
            ref={disclosureProps.ref}
            {...disclosureProps}
            title="Éditer l’archive"
            icon={IoPencil}
          />
        )}
      </DialogDisclosure>
      <ArchiveEditDialog state={editDialog} archiveId={archive.id} />
    </>
  );
});

export const ArchiveNode = memo(
  ({ children, archive, tooltip, editable, variant }) => {
    return (
      <>
        {editable && (
          <EditorBlockCapsuleToolbarActions>
            <EditArchiveAction archive={archive} />
          </EditorBlockCapsuleToolbarActions>
        )}
        <EditorNodeCard variant={variant}>
          <EditorNodeLayout>
            <EditorNodeTooltip tooltip={tooltip}>
              <EditorNodeIcon>
                <ZipFile />
              </EditorNodeIcon>
            </EditorNodeTooltip>
            <EditorNodeBody>
              <ChangeText rawText={archive.archiveTitle}>
                {archive.archiveTitle}
              </ChangeText>
            </EditorNodeBody>
            {children}
          </EditorNodeLayout>
        </EditorNodeCard>
      </>
    );
  },
);

ArchiveNode.fragments = {
  archive: gql`
    fragment ArchiveNode_archive on Archive {
      archiveTitle: title
      cloudPath
    }
  `,
};
