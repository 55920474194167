import styled from "@xstyled/styled-components";
import { Button as AriakitButton } from "ariakit/button";
import { createContext, forwardRef, memo, useContext, useMemo } from "react";
import { VscGripper } from "react-icons/vsc";
import { createTeleporter } from "react-teleporter";
import { Tooltip } from "swash/Tooltip";

export const EditorBlockCapsuleToolbarButton = memo(
  forwardRef(
    (
      {
        icon: Icon,
        title,
        onClick,
        onMouseDown,
        onMouseUp,
        onFocus,
        onBlur,
        "aria-expanded": ariaExpanded,
        ...props
      },
      ref,
    ) => {
      const readOnly = useReadOnly();
      return (
        <Tooltip ref={ref} tooltip={title} aria-expanded={ariaExpanded}>
          <AriakitButton
            className="flex cursor-pointer rounded-full border border-transparent bg-transparent p-1 text-base text-transparent transition"
            onClick={(event) => {
              event.stopPropagation();
              if (onClick) onClick(event);
            }}
            onMouseDown={(event) => {
              event.stopPropagation();
              if (onMouseDown) onMouseDown(event);
            }}
            onMouseUp={(event) => {
              event.stopPropagation();
              if (onMouseUp) onMouseUp(event);
            }}
            onFocus={(event) => {
              event.preventDefault();
              event.stopPropagation();
              if (onFocus) onFocus(event);
            }}
            onBlur={(event) => {
              event.preventDefault();
              event.stopPropagation();
              if (onBlur) onBlur(event);
            }}
            {...props}
            type="button"
            data-editor-block-capsule-toolbar-button=""
            aria-label={title}
            disabled={readOnly}
          >
            <Icon />
          </AriakitButton>
        </Tooltip>
      );
    },
  ),
);

const InnerEditorBlockCapsuleToolbar = styled.div`
  position: absolute;
  top: -10px;
  right: 0;
  z-index: 2;
  display: flex;
  flex-direction: row-reverse;
  gap: 1;
`;

const stopPropagation = (event) => {
  event.stopPropagation();
};

export const muteEditorEvents = () => {
  return {
    onBeforeInput: stopPropagation,
    onBlur: stopPropagation,
    onCompositionStart: stopPropagation,
    onCopy: stopPropagation,
    onCut: stopPropagation,
    onDragOver: stopPropagation,
    onDragStart: stopPropagation,
    onFocus: stopPropagation,
    onInput: stopPropagation,
    onKeyDown: stopPropagation,
    onKeyPress: stopPropagation,
    onKeyUp: stopPropagation,
    onPaste: stopPropagation,
    onSelect: stopPropagation,
    onClick: stopPropagation,
    onMouseDown: stopPropagation,
    onMouseUp: stopPropagation,
  };
};

const TeleporterContext = createContext();
const useTeleporter = () => useContext(TeleporterContext);
const ReadOnlyContext = createContext();
const useReadOnly = () => useContext(ReadOnlyContext);

export const EditorBlockCapsuleToolbarProvider = ({ children, readOnly }) => {
  const teleporter = useMemo(() => createTeleporter(), []);
  return (
    <TeleporterContext.Provider value={teleporter}>
      <ReadOnlyContext.Provider value={readOnly}>
        {children}
      </ReadOnlyContext.Provider>
    </TeleporterContext.Provider>
  );
};

export const EditorBlockCapsuleToolbar = ({ children }) => {
  const teleporter = useTeleporter();
  return (
    <InnerEditorBlockCapsuleToolbar {...muteEditorEvents()}>
      {children}
      <teleporter.Target style={{ display: "contents" }} />
    </InnerEditorBlockCapsuleToolbar>
  );
};

export const EditorBlockCapsuleToolbarActions = ({ children }) => {
  const teleporter = useTeleporter();
  return <teleporter.Source>{children}</teleporter.Source>;
};

const InnerEditorBlockCapsuleSidebar = styled.div`
  transition: base;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: grab;
  padding: 0 1;
  font-size: base;
`;

export const EditorBlockCapsuleSidebar = memo(
  forwardRef((props, ref) => {
    return (
      <InnerEditorBlockCapsuleSidebar
        ref={ref}
        data-editor-block-capsule-sidebar=""
      >
        <VscGripper />
      </InnerEditorBlockCapsuleSidebar>
    );
  }),
);

export const EditorBlockCapsuleHighlight = () => (
  <div data-editor-block-capsule-highlight="" />
);

export const EditorBlockCapsuleBody = forwardRef(
  ({ children, fit, style, attributes }, ref) => {
    return (
      <div
        ref={ref}
        data-editor-block-capsule-body=""
        data-fit={fit ? "" : undefined}
        style={{ position: "relative" }}
      >
        {style || attributes ? (
          <div style={style} {...attributes}>
            {children}
          </div>
        ) : (
          children
        )}
      </div>
    );
  },
);

const InnerEditorBlockCapsule = styled.div`
  position: relative;
  display: flex;
  padding-right: 12px;
  pointer-events: auto;

  &[data-expanded] {
    position: unset;
    cursor: default;
    padding-right: 0;
    border-radius: base;

    ${InnerEditorBlockCapsuleToolbar} {
      top: -10px;
      right: -10px;
    }
  }

  &[data-draggable] {
    cursor: grab;
  }

  [data-editor-block-capsule-body] {
    overflow: hidden;
    border: 1;
    border-color: transparent;
    border-radius: base;
    padding: 1;
    flex: 1;

    &[data-fit] {
      padding: 0;
    }

    .public-DraftStyleDefault-unorderedListItem & {
      display: flex;
      &::before {
        content: "•";
        margin-right: 2;
      }
    }

    .public-DraftStyleDefault-orderedListItem & {
      display: flex;
      &::before {
        content: counter(list) "-";
        margin-right: 2;
      }
    }

    blockquote & {
      margin: 0;
      display: inline-flex;
      font-style: italic;

      &::before {
        content: "";
        border-left: 2;
        border-left-color: dusk-darker;
        padding-left: 2;
      }
    }
  }

  &[data-over-position="top"] {
    &:before {
      content: " ";
      display: block;
      height: 2;
      background-color: dusk;
      position: absolute;
      left: 0;
      right: 0;
      top: -1;
    }
  }

  &[data-over-position="bottom"]:before {
    content: " ";
    display: block;
    height: 2;
    background-color: dusk;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1;
  }

  &[data-controlled-hover] {
    &:not([data-readonly]):not([data-expanded])[data-hovered] > {
      [data-editor-block-capsule-body] {
        border-color: grey-lighter;
      }

      [data-editor-block-capsule-sidebar] {
        opacity: 1;
      }
    }

    &:not([data-readonly])[data-hovered]
      [data-editor-block-capsule-toolbar-button],
    [data-editor-block-capsule-toolbar-button]:focus,
    [data-editor-block-capsule-toolbar-button][aria-expanded="true"] {
      background-color: white;
      border-color: layout-border;
      color: grey;

      &:hover,
      &:focus {
        color: blue;
      }
    }
  }

  &:not([data-controlled-hover]) {
    &:not([data-readonly]):not([data-expanded]):hover {
      &:not(:focus-within) [data-editor-block-capsule-body] {
        border-color: grey-lighter;
      }

      [data-editor-block-capsule-sidebar] {
        opacity: 1;
      }
    }

    &:not([data-readonly]):hover [data-editor-block-capsule-toolbar-button],
    [data-editor-block-capsule-toolbar-button]:focus,
    [data-editor-block-capsule-toolbar-button][aria-expanded="true"] {
      background-color: white;
      border-color: layout-border;
      color: grey;

      &:hover,
      &:focus {
        color: blue;
      }
    }
  }

  &:focus-within [data-editor-block-capsule-body] {
    border-color: primary;
  }

  &[data-readonly] [data-editor-block-capsule-sidebar],
  &[data-readonly] [data-editor-block-capsule-toolbar-button] {
    opacity: 0;
    pointer-events: none;
  }

  [data-editor-block-capsule-highlight] {
    position: absolute;
    inset: 0;
    background-color: blue;
    opacity: 0;
    transition: base;
    pointer-events: none;
  }

  &[data-highlighted] [data-editor-block-capsule-highlight] {
    opacity: 0.1;
  }
`;

export const EditorBlockCapsule = forwardRef(
  (
    {
      highlighted,
      overPosition,
      readOnly,
      hovered,
      expanded,
      draggable,
      children,
      change,
      ...props
    },
    ref,
  ) => {
    return (
      <InnerEditorBlockCapsule
        ref={ref}
        data-change={change}
        data-editor-block-capsule=""
        data-controlled-hover={hovered !== undefined ? "" : undefined}
        data-hovered={hovered ? "" : undefined}
        data-over-position={overPosition}
        data-readonly={readOnly ? "" : undefined}
        data-expanded={expanded ? "" : undefined}
        data-draggable={draggable ? "" : undefined}
        data-highlighted={highlighted ? "" : undefined}
        {...props}
      >
        {children}
      </InnerEditorBlockCapsule>
    );
  },
);
